const moment = require("moment");

export const formatDate = (date, format) => {
  return date ? moment(date).format(format ? format : 'yyyy-MM-dd') : '';
}

const inArray = (comparer) => {
  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) return true;
  }
  return false;
};

export const pushIfNotExist = (element, comparer) => {
  if (!inArray(comparer)) {
    this.push(element);
  }
}

export const cloneObject = (param) => {
  return JSON.parse(JSON.stringify(param));
}


export const lowerCaseAllWordsExceptFirstLetters = (string) => {
  if (string) {
    return string.replace(/\S*/g, function (word) {
      const temp = word.charAt(0) + word.slice(1).toLowerCase();
      return temp.replace(/[_]/g, ' ')
    })
  }
}

export const byteLength = (str) => {
  // returns the byte length of an utf8 string
  var s = str?.length;
  for (var i=str?.length-1; i>=0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s+=2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--; //trail surrogate
  }
  return s;
}


export const formatBytes = (bytes, decimals = 2) => {

  if (bytes === 0) return {
    count: 0,
    unit: 'Bytes'
  };


  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    count: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    unit: sizes[i]
  }
}