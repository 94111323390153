import { fetchApi } from "../utils/apiCaller";

export const getPermission = (params = {}) => {
  return fetchApi("/permissions", "GET", null, params);
};
export const addPermission = (body) => {
  return fetchApi("/permissions", "POST", body);
};
export const getPermissionById = (id) => {
  return fetchApi(`/permissions/${id}`, "GET");
};
export const deletePermissionById = (id) => {
  return fetchApi(`/permissions/${id}`, "DELETE");
};
export const editPermissionById = (id, body) => {
  return fetchApi(`/permissions/${id}`, "PUT", body);
};