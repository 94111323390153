const styles = (theme) => ({
    statusChip: {
        color: "#fff",
        fontSize: "15px",
        backgroundColor: "rgba(207, 10, 44, 0.8)",
        padding: "3px",
        borderRadius: "5px",
        height: "28px"
    }
  });
  
  export default styles;