import React from 'react'
import { CircularProgress, withStyles } from "@material-ui/core";
import styles from './styles'
const Spin = ({ isLoading, classes, size = 80, thickness = 3 }) => {
  return (
    <>
      {isLoading &&
        <div className={classes.globalLoading}>
          <CircularProgress
            className={classes.iconLoading}
            color="primary"
            size={size}
            thickness={thickness} />
        </div>
      }
    </>
  )
}

export default withStyles(styles)(Spin)
