import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import styles from "./styles";
import { lowerCaseAllWordsExceptFirstLetters } from "../../utils/helpers/format";
import Chip from "@material-ui/core/Chip";


class CustomChip extends Component {
  render() {
    const { classes, status } = { ...this.props };

    return (
      <Chip
        className={classes.statusChip}
        label={lowerCaseAllWordsExceptFirstLetters(status)}
        color="secondary"
      />
    );
  }
}

export default withStyles(styles)(CustomChip);
