import axios from "axios";
import { API_URL, TOKEN_KEY, REFRESH_TOKEN } from "../constants/urlAPI";
import { createCookie, readCookie,eraseCookie,clearToken } from "./helpers/cookie";
import { openSnackbar } from "../components/CustomNotify";
import { LOGIN_URL } from "../constants";

// create instance normal
const instanceNext = axios.create({
  baseURL: API_URL,
  timeout: 10000,
});



instanceNext.interceptors.request.use(
  (config) => {
    // //for test only
    // createCookie(
    //   TOKEN_KEY,
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEwMDAiLCJuYW1lIjoiIiwicm9sZSI6InN5c3RlbSIsInVzZXJuYW1lIjoiZWNhLWFkbWluIiwicGNic1JvbGUiOiJzeXN0ZW0iLCJ0eXBlIjoiYWNjZXNzVG9rZW4iLCJpYXQiOjE2MTIyNjAwNDUsImV4cCI6MTYxMjI2MDM0NX0.0bUu0agVsTnQ1HSIe0UI9e0MwEHjjncgMljthj-ZLYw"
    // );

    // createCookie(
    //   REFRESH_TOKEN,
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEwMDAiLCJuYW1lIjoiIiwicm9sZSI6InN5c3RlbSIsInVzZXJuYW1lIjoiZWNhLWFkbWluIiwicGNic1JvbGUiOiJzeXN0ZW0iLCJ0eXBlIjoicmVmcmVzaFRva2VuIiwiaWF0IjoxNjEyMjU5NjUwLCJleHAiOjE3MDU1NzE2NTB9.0X5oSdM3h22Ko6euow6U5zWWdZMnWqStJzdMaWfaJtU"
    // );


    const token = readCookie(TOKEN_KEY);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.AccessControlAllowOrigin = `*`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instanceNext.interceptors.response.use(
  function (response) {

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
      switch (error.response.status) {
        case 401: {
          //clear cookie
          clearToken();
          //redirect
          setTimeout(() => {
            window.location.href = LOGIN_URL;
          },500)
        }
        case 403: {
          openSnackbar({
            msg: "You don't have permission to do this.",
            type: "error",
          });
        }
        default:
          break;
      }
    }

    openSnackbar({ msg: "Something Went Wrong.", type: "error" });
    return Promise.reject(error);
  }
);

export async function fetchApi(
  endpoint,
  method = "GET",
  body,
  params = {},
  sourceToken = null
) {
  return instanceNext({
    method: method,
    url: endpoint,
    data: body,
    params: params,
    cancelToken: sourceToken,
  });
}

export async function fetchAllApi(requests = []) {
  return axios.all(requests);
}
