import { GET_STATUS_REQUEST } from '../constants/actions'

const initialState = {
  status: []
}

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUS_REQUEST: {
      state.status = action.status
      return { ...state }
    }
    default:
      return state
  }
}

export default statusReducer