const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    // minHeight: 120,
    padding: "0 80px 0 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      width: "100%",
    }
  },
  logoContainer: {
    width: 350,
    backgroundColor: '#fff',
    textAlign: 'center',
    [theme.breakpoints.down("lg")]: {
        width: 250,
    },
    [theme.breakpoints.down("md")]: {
        width: 220,
    },
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: 120,
      paddingTop: 15
    },
    [theme.breakpoints.down("xs")]: {
      width: 100,
      height: 120,
      paddingTop: 25
    }
  },
  logo: {
    width: 80,
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      width: 65,
    },
    [theme.breakpoints.down("xs")]: {
      width: 50,
    }
  },
  title: {
    flexGrow: 0.1,
  },
  appTitle: {
    flexGrow: 1,
    fontWeight: "normal",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "50px"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    }
  },
  btnLogout: {
    margin: theme.spacing(2),
    padding: "0 10px",
    height: 30,
    color: "#fff",
    width: 80,
    cursor: "pointer",
    //fontWeight: "bold",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      margin: 0,
      width: 120,
      position: "absolute", 
      top: "-15px", 
      right: 0,
    }
  },
  menuContent: {
    position: "absolute",
    listStyleType: "none",
    margin: "0px",
    backgroundColor: "white",
    height: "40px",
    right: "70px",
    borderRadius:'2px',
    border: "1px solid rgba(0, 0, 0, 0.2)",
    "&::before":{
      border: "10px solid blue",
    },
    [theme.breakpoints.down("sm")]: {
      right: "55px",
    },
    [theme.breakpoints.down("xs")]: {
      right: "27px",
      top: "15px"
    }
  },
  menuItem: {
    width: "100px",
    height: "40px",
    textDecoration: "none",
    //borderLeft: "5px solid white",
    display: "block",
    color: "black",
    lineHeight: '15px',
    cursor:'pointer',
    [theme.breakpoints.down("sm")]: {
      width: "90px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80px",
    } 
    
    // "&:hover": {   
    //   backgroundColor: 'rgba(0, 0, 0, 0.2)',
    //   borderLeft: "5px solid #cf0a2c",
    //   cursor:'pointer'
    // },
  },
});

export default styles;
