import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  Box,
  // Skeleton
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import PropTypes from "prop-types";
import styles from "./styles";
import {
  lowerCaseAllWordsExceptFirstLetters,
  formatDate,
} from "../../utils/helpers/format";

import { EVENT_STATUS } from "../../constants/globalConfigs";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    verticalAlign: "middle",
    marginTop: 50,
    padding: 20,
    fontSize: "0.9rem !important",
  },
}))(Tooltip);

const DescriptionTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    verticalAlign: "middle",
    padding: "2px !important",
    maxWidth: "800px !important",
    fontSize: "0.7rem !important",
  },
}))(Tooltip);
class CustomTable extends Component {
  handleChangePage = (e, page) => {
    const { fetchData, filterParams, pagination } = this.props;
    pagination.current = page;
    filterParams.page = page;

    fetchData(pagination, filterParams);
  };

  renderTableCell = (data, key) => {
    const { tableName } = this.props;
    const { classes } = this.props;
    switch (tableName) {
      case "announcements":
        return (
          <React.Fragment>
            {/* name */}
            <TableCell
              width="25%"
              style={{ maxWidth: "8vw" }}
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.name}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.name}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* createDate */}
            <TableCell width="10%" align="center" className={classes.tableCell}>
              <LightTooltip
                title={data.createDate}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.createDate}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* createBy */}
            <TableCell width="10%" align="center" className={classes.tableCell}>
              <LightTooltip
                title={data.createBy}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.createBy}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* Status*/}
            <TableCell
              width="12%"
              style={{ maxWidth: "4vw" }}
              align="center"
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.status}
                placement="right-end"
                align="center"
                className={classes.tooltip}
              >
                <Box style={{ display: "inline-flex" }}>
                  <Typography
                    className={classes.cellContent}
                    placement="bottom-end"
                  >
                    {lowerCaseAllWordsExceptFirstLetters(data.status)}
                  </Typography>
                  {data.comment && data.status === EVENT_STATUS.DRAFT ? (
                    <ChatOutlinedIcon
                      color="primary"
                      style={{ marginLeft: "10px" }}
                    />
                  ) : null}
                </Box>
              </LightTooltip>
            </TableCell>

            {/* approved At */}
            {/* <TableCell width="10%" align="center" className={classes.tableCell}>
              <LightTooltip
                title={data.approvedDate}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.approvedDate}
                </Typography>
              </LightTooltip>
            </TableCell> */}

            {/* approvedBy*/}
            <TableCell
              width="10%"
              style={{ maxWidth: "3vw" }}
              align="center"
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.approvedBy}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.approvedBy}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* campus */}
            <TableCell
              width="12%"
              style={{ maxWidth: "3vw" }}
              align="center"
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.campus.filter(Boolean).join(",")}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.campus.filter(Boolean).join(",")}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* yearGroup */}
            <TableCell
              width="12%"
              style={{ maxWidth: "4vw" }}
              align="center"
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.yearGroup.filter(Boolean).join(",")}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.yearGroup.filter(Boolean).join(",")}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* gradeClass */}
            <TableCell
              width="14%"
              style={{ maxWidth: "5vw" }}
              align="center"
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.gradeClass.filter(Boolean).join(",")}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.gradeClass.filter(Boolean).join(",")}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* action */}
            <TableCell width="5%" align="center" className={classes.tableCell}>
              {data.action}
            </TableCell>
          </React.Fragment>
        );
      case "permission":
        return (
          <React.Fragment>
            {/* staffuser */}
            <TableCell
              width="15%"
              style={{ maxWidth: "10vw" }}
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.username}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.username}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* createDate */}
            <TableCell width="10%" align="center" className={classes.tableCell}>
              <LightTooltip
                title={data.createDate}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.createDate}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* campus */}
            <TableCell
              width="16%"
              style={{ maxWidth: "5vw" }}
              align="center"
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.campus.filter(Boolean).join(",")}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {/* {data.permissions.campus.name.} */}
                  {data.campus.filter(Boolean).join(",")}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* yearGroup */}
            <TableCell
              width="16%"
              style={{ maxWidth: "5vw" }}
              align="center"
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.yearGroup.filter(Boolean).join(",")}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.yearGroup.filter(Boolean).join(",")}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* gradeClass */}
            <TableCell
              width="16%"
              style={{ maxWidth: "5vw" }}
              align="center"
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.gradeClass.filter(Boolean).join(",")}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.gradeClass.filter(Boolean).join(",")}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* action */}
            <TableCell width="5%" align="center" className={classes.tableCell}>
              {data.action}
            </TableCell>
          </React.Fragment>
        );
      case "changeRequestTable": {
        const { isParent } = JSON.parse(data.description);

        const createData = (label, oldInformation, newInformation) => {
          return { label, oldInformation, newInformation };
        };

        const createDataTable = () => {
          const { newInformation, oldInformation, isParent } = JSON.parse(
            data.description
          );
          if (isParent) {
            return [
              createData(
                "Parent Name",
                oldInformation.name,
                newInformation.name
              ),
              createData(
                "Parent Relationship",
                oldInformation.relationship,
                newInformation.relationship
              ),
              createData(
                "Parent Email",
                oldInformation.email,
                newInformation.email
              ),
              createData(
                "Parent Contact Number",
                oldInformation.phone,
                newInformation.phone
              ),
            ];
          } else {
            return [
              createData(
                "Student Name",
                oldInformation.fullname,
                newInformation.fullname
              ),
              createData(
                "Date of Birth",
                formatDate(oldInformation.dateOfBirth, "DD MMM,YYYY"),
                formatDate(newInformation.dateOfBirth, "DD MMM,YYYY")
              ),
            ];
          }
        };

        const rows = createDataTable();
        const tableHead = ["Label", "Old Value", "New Value"];

        return (
          <React.Fragment>
            {/* parentId */}
            <TableCell
              align="center"
              width="5%"
              style={{ maxWidth: "10vw" }}
              className={classes.tableCell}
            >
              <Typography
                className={classes.cellContent}
                placement="bottom-end"
              >
                {data.id}
              </Typography>
            </TableCell>

            {/* Requested Date */}
            <TableCell width="7%" align="center" className={classes.tableCell}>
              <LightTooltip
                title={data.requestedDate}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.requestedDate}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* status */}
            <TableCell
              width="10%"
              style={{ maxWidth: "5vw" }}
              align="center"
              className={classes.tableCell}
            >
              {" "}
              <LightTooltip
                title={data.status}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.status}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/*Description */}
            <TableCell width="13%" className={classes.tableCell}>
              <DescriptionTooltip
                placement="top"
                //  open={true}
                //  className={classes.tooltipDescription}
                title={
                  <Box>
                    <TableContainer
                      component={Paper}
                      className={classes.descriptionTable}
                    >
                      <Table>
                        {tableHead !== undefined ? (
                          <TableHead>
                            <TableRow>
                              {tableHead.map((prop, index) => {
                                return (
                                  <TableCell
                                    className={
                                      classes.tableCell +
                                      " " +
                                      classes.tableHeadCell
                                    }
                                    key={index}
                                  >
                                    {prop}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                        ) : null}
                        <TableBody>
                          {rows && rows.length > 0 ? (
                            rows.map((item, key) => {
                              return (
                                <TableRow
                                  key={key}
                                  className={key % 2 === 0 && classes.tableRowDark}
                                >
                                  <TableCell
                                    width="16%"
                                    // style={{ maxWidth: "5vw" }}
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    <LightTooltip
                                      title={item.label}
                                      placement="right-end"
                                      className={classes.tooltip}
                                    >
                                      <Typography
                                        className={classes.cellContent}
                                        placement="bottom-end"
                                      >
                                        {item.label}
                                      </Typography>
                                    </LightTooltip>
                                  </TableCell>

                                  <TableCell
                                    width="16%"
                                    // style={{ maxWidth: "5vw" }}
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    <LightTooltip
                                      title={item.oldInformation}
                                      placement="right-end"
                                      className={classes.tooltip}
                                    >
                                      <Typography
                                        className={classes.cellContent}
                                        placement="bottom-end"
                                      >
                                        {item.oldInformation}
                                      </Typography>
                                    </LightTooltip>
                                  </TableCell>

                                  <TableCell
                                    width="16%"
                                    // style={{ maxWidth: "5vw" }}
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    <LightTooltip
                                      title={item.newInformation}
                                      placement="right-end"
                                      className={classes.tooltip}
                                    >
                                      <Typography
                                        className={classes.cellContent}
                                        placement="bottom-end"
                                      >
                                        {item.newInformation}
                                      </Typography>
                                    </LightTooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell
                                colSpan={6}
                                className={`${classes.tableCell} ${classes.tableCellEmpty}`}
                              >
                                No Data
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                }
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {isParent ? "Parent Name ..." : "Student Name ..."}
                </Typography>
              </DescriptionTooltip>
            </TableCell>

            {/* Campus */}
            <TableCell
              width="10%"
              style={{ maxWidth: "5vw" }}
              align="center"
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.campus}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.campus}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* yearGroup */}
            <TableCell
              width="10%"
              style={{ maxWidth: "5vw" }}
              align="center"
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.yearGroup}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.yearGroup}
                </Typography>
              </LightTooltip>
            </TableCell>
            {/* Grade Class */}
            <TableCell
              width="10%"
              style={{ maxWidth: "5vw" }}
              align="center"
              className={classes.tableCell}
            >
              <LightTooltip
                title={data.gradeClass}
                placement="right-end"
                className={classes.tooltip}
              >
                <Typography
                  className={classes.cellContent}
                  placement="bottom-end"
                >
                  {data.gradeClass}
                </Typography>
              </LightTooltip>
            </TableCell>

            {/* action */}
            <TableCell width="5%" align="center" className={classes.tableCell}>
              {data.action}
            </TableCell>
          </React.Fragment>
        );
      }

      default:
        return;
    }
  };

  render() {
    const { classes, tableHead, tableData, pagination } = this.props;

    return (
      <React.Fragment>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            {tableHead !== undefined ? (
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => {
                    return (
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                        key={key}
                      >
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {tableData && tableData.length > 0 ? (
                tableData.map((item, key) => {
                  return (
                    <TableRow
                      key={key}
                      className={key % 2 === 0 && classes.tableRowDark}
                    >
                      {this.renderTableCell(item, key)}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    className={`${classes.tableCell} ${classes.tableCellEmpty}`}
                  >
                    No Data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {tableData && tableData.length > 0 ? (
          <Pagination
            color="primary"
            shape="rounded"
            onChange={this.handleChangePage}
            showFirstButton={true}
            showLastButton={true}
            page={pagination.current || 1}
            count={Math.ceil(pagination.total / pagination.pageSize) || 20}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(styles)(CustomTable);
