const styles = (theme) => ({
    root: {
        display: "flex",
    },
    drawer: {
        width: 350,
        flexShrink: 0,
        backgroundColor: '#fff',
        [theme.breakpoints.down("lg")]: {
            width: 250,
        },
        [theme.breakpoints.down("md")]: {
            width: 220,
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        }
    },
    drawerPaper: {
        width: "100%",
        zIndex: 99,
        flexShrink: 0,
        position: "relative",
        border: "none",
        height: "auto"

    },
    drawerContainer: {
        overflow: "auto",
        borderTop: "1px solid #d6d6d6",

    },
    listPadding: {
        padding: 0
    },
    menuLink: {
        textDecoration: "none"
    },
    menuItem: {
        height: 70,
        backgroundColor: '#fff',
        color: "#414042",
    },
    itemText: {
        fontWeight: "650 !important",
        fontFamily: '"Segoe UI",sans-serif',
        // color: "#a1a1a1"
    },
    iconMenu: {
        marginLeft: "20px",
        color: '#414042'
    },
    menuLinkActive: {
        "& > :first-child": {
            backgroundColor: '#e6e6e6',
            color: '#414042'
        },
        "& $iconMenu": {
            color: '#414042'
        }           
    }

});

export default styles;
