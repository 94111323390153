import Announcements from "../pages/Announcements";
import AddAnnouncements from "../pages/Announcements/components/add-announcements";
import Events from "../pages/Events";


export const ADMIN_ROUTES = [
    {
        path: '/',
        name: 'Announcements',
        exact: true,
        component: Announcements
    },
    {
        path: '/announcements',
        name: 'Announcements',
        exact: true,
        component: Announcements
    },
    {
        path: '/events',
        exact: true,
        name: 'Events',
        component: Events
    },
    {
        path: '/announcements/Add',
        exact: true,
        name: 'Add Announcements',
        component: AddAnnouncements
    },
    {
        path: '/announcements/view/:id',
        exact: true,
        name: 'View Announcements',
        component: AddAnnouncements
    }
]