import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
// import Proptyles from "prop-types";
import { AppBar, Toolbar, Typography, ClickAwayListener, MenuItem, MenuList, Paper } from "@material-ui/core";
import logo from "../../../assets/img/nas-logo.png";
import styles from "./styles";
import { LOGIN_URL, TOKEN_KEY } from "../../../constants";
import { Link } from 'react-router-dom'
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { connect } from 'react-redux';
import { clearToken, eraseCookie } from "../../../utils/helpers/cookie";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
  }

  logout = () => {
    //remove cookie
    clearToken()
    window.location.href = LOGIN_URL;
  };
  handleClose = () => {
    this.setState({ isOpenMenu: false });
  };
  handleMenu = (event) => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    const { classes, userInfo } = this.props;

    const open = Boolean(this.state.isOpenMenu);
    const renderMenu = (
      <Paper className={classes.paper}>
        <ClickAwayListener onClickAway={this.handleClose}>
          <MenuList className={classes.menuContent} >
            <MenuItem  onClick={this.logout} className={classes.menuItem} >Logout</MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Paper>
    );


    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" className={classes.title}>
              <Link to="/admin">
                <div className={classes.logoContainer}>
                  <img className={classes.logo} src={logo} alt="NAS"/>
                </div>
              </Link>
            </Typography>
            <Typography variant="h6" className={classes.appTitle}>
              Announcements Management
            </Typography>
            <div style={{position: "relative" ,width: "200px"}}>
              <Typography
                aria-controls={open ? 'menu-list-grow' : undefined}
                className={classes.btnLogout}
                variant="button"
                onClick={this.handleMenu}
              >
                {userInfo.username}
                <ArrowDropDownIcon />
              </Typography>
              {open && renderMenu}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.permissionReducer.userInfo
})



export default connect(mapStateToProps, null)(withStyles(styles)(Header))
