import React, { Component } from 'react';

class Events extends Component {
    render() {
        return (
            <div>
                This feature is under development
            </div>
        );
    }
}

export default Events;
