import React, { Component } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import styles from "./styles";
import { EVENT_STATUS } from "../../constants/globalConfigs";
import { formatDate } from "../../utils/helpers/format";
import CustomChip from '../CustomChip';

class StatusGroupAnnouncement extends Component {
  constructor() {
    super();

    this.state = {
      statusAnnouncement: []
    };
  }

  componentDidMount = () => {
    const { info,status } = { ...this.props };
    let statusInfoDraff = [
        {
            label: "Created by",
            value: info.createdBy
        },
        {
            label: "Created date",
            value: info.createdAt ? formatDate(info.createdAt,'DD MMM YYYY') : null
        },
        {
            label: "",
            value: status
        }
    ];

    if(status && [EVENT_STATUS.APPROVED,EVENT_STATUS.ARCHIVE].indexOf(status) > -1) {
        //check if approveby exist
        statusInfoDraff.push({
            label: "Approved By",
            value: info.approvedBy
        });

        //check Approvedate exist 
        statusInfoDraff.push({
            label: "Approved Date",
            value: info.approvedAt ? formatDate(info.approvedAt,'DD MMM YYYY') : null
        });
    }
    
    this.setState({
        statusAnnouncement: statusInfoDraff
    });

  }

  render() {
    const { classes } = { ...this.props };
    const { statusAnnouncement } = this.state;

    return (
      <Box display="flex" className={classes.groupStatus}>
        <Grid container>
            {!!statusAnnouncement &&
            statusAnnouncement.map((item,index) => {
                return (
                <Grid item key={index} className={` ${item.label || classes.status} ${classes.groupItem}`} xs={4}>
                    { item.label ? <Typography variant="subtitle2">{ item.label } : </Typography> : null}
                    { 
                      item.label ? 
                        <Typography variant="body2" style={{ paddingLeft: 8 }}> { item.value } </Typography>
                      : <CustomChip status={ item.value } />
                    }
                </Grid>
                )
            })}
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(StatusGroupAnnouncement);
