import { SET_PARAMS_TABLE } from '../constants/actions';

const initialState = {
  params: [],
}

const paramsTable = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARAMS_TABLE: {
      state.params = action.params
      return { ...state }
    }
    default:
      return state
  }
}
export default paramsTable