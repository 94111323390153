import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import Proptyles from "prop-types";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import styles from "./styles";
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux'
import { ROLE } from "../../../constants";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      selectedKey: "",
    };
  }

  componentDidMount() {
    const { path } = this.props.match;

    const indexKey = "announcements";
    const menuActive = [
      { path: "/announcements", key: "announcements" },
      { path: "/events", key: "events" },
      { path: "/permissions", key: "permissions" },
      { path: "/change-request", key: "change-request" },
    ];
    if (path === "/admin") {
      this.setState({
        selectedKey: indexKey,
      }, () => {
        this.props.history.push('/admin/announcements')
      });
    } else {
      for (let i = 0; i < menuActive.length; i++) {
        if (path.indexOf(menuActive[i].path) > -1) {
          this.setState({
            selectedKey: menuActive[i].key,
          });
        }
      }
    }
  }

  toggleDrawer = (value) => {
    this.setState({
      open: value,
    });
  };
  onOpenSelected = (data) => {
    const { history } = this.props;

    switch (data.key) {
      case "announcements":
        history.push("/admin/announcements");
        break;
      case "events":
        history.push("/admin/events");
        break;
      case "permissions":
        history.push("/admin/permissions",);
        break;
      case "change-request":
        history.push("/admin/change-request");
        break;
      default:
        break;
    }
  };
  renderList = () => {
    let listMenu = null;
    const { classes, userInfo } = this.props;
    listMenu = (
      <div className={classes.drawerContainer}>
        <List component="div" className={classes.listPadding}>
          <NavLink
            to="/admin/announcements"
            activeClassName={classes.menuLinkActive}
          >
            <ListItem
              onClick={this.onOpenSelected(this.state.selectedKey)}
              className={classes.menuItem}
              button
            >
              <ListItemText>
                <Typography className={classes.itemText}>
                  Announcements
                </Typography>
              </ListItemText>
              <ListItemIcon>
                <Icon className={classes.iconMenu}>announcement</Icon>


              </ListItemIcon>
            </ListItem>
          </NavLink>

          {/* <NavLink
            to="/admin/events"
            activeClassName={classes.menuLinkActive}
          >
            <ListItem
              onClick={this.onOpenSelected(this.state.selectedKey)}
              className={classes.menuItem}
              button
            >
              <ListItemText>
                <Typography className={classes.itemText}>Events</Typography>
              </ListItemText>
              <ListItemIcon>
                <Icon className={classes.iconMenu}>eventnote</Icon>
              </ListItemIcon>
            </ListItem>
          </NavLink> */}

          {userInfo.role && userInfo.role === ROLE.SYSTEM && <NavLink
            to="/admin/permissions"
            activeClassName={classes.menuLinkActive}
          >
            <ListItem
              onClick={this.onOpenSelected(this.state.selectedKey)}
              className={classes.menuItem}
              button
            >
              <ListItemText>
                <Typography className={classes.itemText}>Permissions</Typography>
              </ListItemText>
              <ListItemIcon>
                <Icon className={classes.iconMenu}>people</Icon>
              </ListItemIcon>
            </ListItem>
          </NavLink>
          }
          <NavLink
            to="/admin/change-request"
            activeClassName={classes.menuLinkActive}
          >
            <ListItem
              onClick={this.onOpenSelected(this.state.selectedKey)}
              className={classes.menuItem}
              button
            >
              <ListItemText>
                <Typography className={classes.itemText}>Change Request</Typography>
              </ListItemText>
              <ListItemIcon>
                <Icon className={classes.iconMenu}>system_update_alt</Icon>
              </ListItemIcon>
            </ListItem>
          </NavLink>

        </List>
      </div>
    );

    return listMenu;
  }

  render() {
    const { open } = this.state;
    const { classes } = this.props;
    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={open}
        onClose={() => this.toggleDrawer(false)}
      >
        {this.renderList()}
      </Drawer>
    );
  }
}

Sidebar.proptyles = {
  classes: Proptyles.object,
  // path: Proptyles.object
};

const mapStateToProps = (state) => ({
  userInfo : state.permissionReducer.userInfo
})


export default connect(mapStateToProps, null)(withStyles(styles)(withRouter(Sidebar)))
