export const styles = () => ({
    
    "@global":{
        "html,body": {
            height: "100%"
        },
        "body" :{
            "overflow": "auto !important",
            color: '#414042'
        },
        ".placeholder" : {
            color: "rgba(0,0,0,0.4)",
            fontStyle: "normal"
        },
        "a": {
            textDecoration: "none !important"
        }
    }
})