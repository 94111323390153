import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import styles from "./styles";
import { Editor } from "@tinymce/tinymce-react";
import { uploadImg } from "../../actions/announcementActions";
import { byteLength, formatBytes } from "../../utils/helpers/format";
import { DEBOUNCED_TIME_EDITOR, EDITOR_KEY } from "../../constants";
import debounce from "lodash.debounce";

class CustomEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 0,
    };
    this.onChange = debounce(this.props.onChange, DEBOUNCED_TIME_EDITOR);
  }

  handleEditorChange = (...content) => {
    this.onChange(content[0], formatBytes(byteLength(content[0])));
  };

  render() {
    return (
      <Editor
        apiKey={EDITOR_KEY}
        initialValue={this.props.value}
        init={{
          selector: "#editor",
          // skin_url: './editorTheme/',
          menubar: false,
          branding: false,
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          height: 400,
          image_caption: true,
          elementpath: false,
          fontsize_formats:
            "8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 22pt 24pt 32pt 48pt",
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_noneditable_class: "mceNonEditable",
          toolbar_mode: "wrap",
          image_advtab: true,
          image_caption: true,
          relative_urls: false,
          remove_script_host: false,
          images_upload_handler: async function (
            blobInfo,
            success,
            fail,
            progress
          ) {
            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());
            formData.append("isEditor", true);
            try {
              const res = await uploadImg(formData);
              success(res.data[0].url);
            } catch (error) {
              console.log(error);
              fail("HTTP Error: " + error, { remove: true });
              return;
            }
          },
          setup: (ed) => {},
          quickbars_insert_toolbar: false,
          inline: false,
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #414042 }",
          plugins:
            "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons",
          toolbar:
            "bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | table image undo redo | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile media template link anchor codesample | ltr rtl",
        }}
        onEditorChange={this.handleEditorChange}
        disabled={this.props.disabled}
        readonly={this.props.disabled ? 1 : 0}
      />
    );
  }
}
export default withStyles(styles)(CustomEditor);
