import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import styles from "./styles";
import { EVENT_STATUS, ROLE } from "../../constants/globalConfigs";
class ButtonGroupAnnouncement extends Component {

  renderSaveSubmitBtn = (status) => {
    return status === EVENT_STATUS.DRAFT;
  }

  renderApproveRejectBtn = (status) => {
    return this.checkPermission() && status === EVENT_STATUS.AWAITING_APPROVAL;
  }

  renderArchiveBtn = (status) => {
    return this.checkPermission() && status === EVENT_STATUS.APPROVED;
  }

  renderDraftBtn = (status) => {
    return this.checkPermission() && status === EVENT_STATUS.ARCHIVE;
  }

  checkPermission = () => {
    const { userInfo } = this.props;
    if(
      userInfo && 
      (
        userInfo.role === ROLE.SYSTEM || 
        (userInfo.role === ROLE.MANAGER && userInfo.announcementApprover)
      )
    ) 
    {
      return true;
    }
    return false;
  }

  render() {
    const { status, classes, isEdit } = { ...this.props };

    return (
      <Box className={classes.groupButton}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          type="submit"
          value="Save"
          onClick={this.props.handleSaveDraft}
          className={`${this.renderSaveSubmitBtn(status) ? null : classes.hidden} ${classes.btnItem}`}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          type="submit"
          value="Submit"
          onClick={this.props.handleSubmitDraft}
          className={`${this.renderSaveSubmitBtn(status) ? null : classes.hidden  } ${classes.btnItem}`}
        >
          Submit
        </Button>

        <Button
          variant="contained"
          size="small"
          color="primary"
          type="submit"
          value="Approve"
          onClick={this.props.handleApproval}
          className={`${this.renderApproveRejectBtn(status) ? null : classes.hidden} ${classes.btnItem}`}
        >
          Approve
        </Button>

        <Button
          variant="contained"
          size="small"
          color="primary"
          type="submit"
          value="Reject"
          onClick={this.props.handleDraft}
          style={{backgroundColor: '#EC5840', color: '#fff'}}
          className={`${this.renderApproveRejectBtn(status) ? null : classes.hidden} ${classes.btnItem} ${classes.btnHover}`}
        >
          Reject
        </Button>

        <Button
          variant="contained"
          size="small"
          color="primary"
          type="submit"
          value="Archive"
          onClick={this.props.handleArchive}
          className={`${this.renderArchiveBtn(status) ? null : classes.hidden} ${classes.btnItem}`}
        >
          Archive
        </Button>

        <Button
          variant="contained"
          size="small"
          color="primary"
          type="submit"
          value="Draft"
          onClick={this.props.handleDraft}
          className={`${this.renderDraftBtn(status) ? null : classes.hidden} ${classes.btnItem}`}
        >
          Draft
        </Button>

        <Link to="/admin/announcements">
          <Button
            variant="contained"
            size="small"
            color="secondary"
            value="Cancel"
            style={{backgroundColor: '#939598', color: '#fff'}}
            className={`${classes.btnItem} ${classes.btnHover}`}
          >
            {isEdit ? "Back" : "Cancel"}
          </Button>
        </Link>
      </Box>
    );
  }
}

export default withStyles(styles)(ButtonGroupAnnouncement);
