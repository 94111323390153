import React, { Component } from "react";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Grid, Input, Box, InputLabel, FormControl } from "@material-ui/core";
import { DEBOUNCED_TIME, FILTER_NAME } from "../../constants/globalConfigs";
import styles from "./styles";
// import CustomSelect from "../CustomSelect";
import Select from "react-select";
import { FILTER_ANNOUNCEMENTS } from "../../constants/componentConfigs";
import cloneDeep from "clone-deep";
//import { MultiValueContainer } from "react-select/src/components/MultiValue";
// import { FILTER_ANNOUNCEMENTS } from "../../constants/componentConfigs";
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);
class GroupFilter extends Component {
  constructor(props) {
    super(props);
    this.emitChangeDebounced = debounce(this.emitChangeFilter, DEBOUNCED_TIME);
    const filters = cloneDeep(FILTER_ANNOUNCEMENTS);

    // this variable use to verify reset  GroupFilter value when user add new group by modal
    this.groupPage = "group-setting";
    this.state = {
      customFilterAnnoucements: filters,
      customFilterAnnoucementsClone: filters,
      valueName: "",
      valueYearGroup: [],
      valueCampus: [],
      valueGradeClasses: [],
      valueFilters: {},
      showYear: false,
      showGrade: false,
    };
  }

  componentDidUpdate(prevProps) {
    // reset filter Setting Group when add new group
    if (
      this.props.isResetFilter !== prevProps.isResetFilter &&
      this.props.isResetFilter
    ) {
      this.setState({
        valueObjectPageGroup: undefined,
        valueNamePageGroup: "",
      });
    }
  }

  // onchange input
  handleChangeInput = (e) => {
    const value = e.target.value.trim();
    const name = e.target.name;
    console.log("value: ", value);
    const { valueFilters } = this.state;
    // reset filter Setting Group when add new group
    valueFilters[name] = value ? value : undefined;

    if (this.props.currentPage === this.groupPage) {
      this.setState({
        valueNamePageGroup: value,
      });
    }

    this.emitChangeDebounced(value, name);
  };

  renderValueForSelect = (name) => {
    switch (name) {
      case "yearGroups":
        return this.state.valueYearGroup;
      case "campus":
        return this.state.valueCampus;
      case "gradeClasses":
        return this.state.valueGradeClasses;
      default:
        break;
    }
  };
  disabledSelect = (name) => {
    switch (name) {
      case "yearGroups":
        return this.state.valueCampus.length === 0;
      case "gradeClasses":
        return this.state.valueYearGroup.length === 0;
      default:
        return false;
    }
  };
  // onchange select option
  handleChangeSelect = (options, name) => {
    // const { value, name } = e.target;
    const { valueFilters } = {
      ...this.state,
    };
    let tempOptions = [];
    const isReset = options && options.length > 0 ? false : true;
    if (!(options && options.length > 0)) {
      this.emitChangeDebounced(null, name, isReset);
    }

    valueFilters[name] = options ? options : undefined;

    switch (name) {
      case "status":
        this.emitChangeDebounced(options.value, name);
        break;
      case "campus":
        for (let i = 0, iCampuses = options.length; i < iCampuses; i++) {
          tempOptions.push({
            value: options[i].value,
            label: options[i].label,
          });
          this.emitChangeDebounced(options[i].value, name, isReset);
        }
        this.setState({
          valueCampus: tempOptions,
        });
        break;
      case "yearGroups":
        for (let i = 0, iYearGroups = options.length; i < iYearGroups; i++) {
          tempOptions.push({
            value: options[i].value,
            label: options[i].label,
          });
          this.emitChangeDebounced(options[i].value, name, isReset);
        }
        this.setState({
          valueYearGroup: tempOptions,
        });
        break;
      case "gradeClasses":
        for (
          let j = 0, iGradeClasses = options.length;
          j < iGradeClasses;
          j++
        ) {
          tempOptions.push({
            value: options[j].value,
            label: options[j].label,
          });
          this.emitChangeDebounced(options[j].value, name, isReset);
        }
        this.setState({
          valueGradeClasses: tempOptions,
        });
        break;
      default:
    }
  };

  // emit event call api fetch data
  emitChangeFilter(value = [], key, isReset) {
    const {
      filterOptionsInit,
      fetchData,
      assignYearGroups,
      assignGradeClasses,
    } = this.props;
    const { valueCampus, valueYearGroup, valueGradeClasses, valueFilters } = {
      ...this.state,
    };

    // const filterParams = Object.assign({}, urlParams.params);
    // filterParams[key] = value ? value : undefined;

    const filters = cloneDeep(filterOptionsInit);

    let filteredYearGroups = [];
    let filteredGradeClasses = [];

    let filterSelectedYearGroups = [...valueYearGroup];
    let filterSelectedGradeClasses = [...valueGradeClasses];

    filteredYearGroups = [...filters[2].options];
    filteredGradeClasses = [...filters[3].options];

    if (key === FILTER_NAME.CAMPUS) {
      if (isReset) {
        valueFilters[FILTER_NAME.YEARGROUPS] = [];
        valueFilters[FILTER_NAME.GRADECLASSES] = [];

        //reset value for all options
        this.setState({
          valueYearGroup: [],
          valueGradeClasses: [],
        });
      } else {
        //filter YearGroup By Campus
        const campusArr = [...valueCampus].map((c) => {
          return +c.value;
        });

        filteredYearGroups = filters[2].options.filter(
          (x) => campusArr.indexOf(x.campusId) > -1
        );

        let yearGroupArr = filteredYearGroups.map((c) => {
          return c.value;
        });

        filterSelectedYearGroups = filterSelectedYearGroups.filter(
          (x) => yearGroupArr.indexOf(x.value) > -1
        );

        //Filter GradeClasses By YearGroups
        if (filterSelectedYearGroups && filterSelectedYearGroups.length > 0)
          yearGroupArr = filterSelectedYearGroups.map((c) => {
            return c.value;
          });
        else if (filteredYearGroups && filteredYearGroups.length > 0)
          yearGroupArr = filteredYearGroups.map((c) => {
            return c.value;
          });

        filteredGradeClasses = filters[3].options.filter(
          (x) => yearGroupArr.indexOf(x.yearGroupId) > -1
        );

        let gradeClassesArr = filteredGradeClasses.map((c) => {
          return c.value;
        });
        filterSelectedGradeClasses = filterSelectedGradeClasses.filter(
          (x) => gradeClassesArr.indexOf(x.value) > -1
        );
        this.setState({
          valueYearGroup: filterSelectedYearGroups,
          valueGradeClasses: filterSelectedGradeClasses,
        });

        assignYearGroups(isReset, filteredYearGroups);
        assignGradeClasses(isReset, filteredGradeClasses);
      }
    }

    if (key === FILTER_NAME.YEARGROUPS) {
      let yearGroupArr = [];

      if (isReset) {
        valueFilters[FILTER_NAME.GRADECLASSES] = [];
        //reset value for grade classes options
        this.setState({
          valueGradeClasses: [],
        });
      } else {
        // Filter YearGroups By Campus
        if (valueCampus && valueCampus.length > 0) {
          const campusArr = valueCampus.map((c) => {
            return +c.value;
          });
          filteredYearGroups = filters[2].options.filter(
            (x) => campusArr.indexOf(x.campusId) > -1
          );

          if (valueYearGroup && valueYearGroup.length > 0) {
            yearGroupArr = valueYearGroup.map((c) => {
              return c.value;
            });
          } else {
            yearGroupArr = filteredYearGroups.map((c) => {
              return c.value;
            });
          }
          filters[2].options = filteredYearGroups;
        } else {
          if (valueYearGroup && valueYearGroup.length > 0) {
            yearGroupArr = valueYearGroup.map((c) => {
              return c.value;
            });
          } else {
            yearGroupArr = filters[2].options.map((c) => {
              return c.value;
            });
          }
        }

        //Filter Grade classes By yeargroups
        filteredGradeClasses = filters[3].options.filter(
          (x) => yearGroupArr.indexOf(x.yearGroupId) > -1
        );
        let gradeClassesArr = filteredGradeClasses.map((c) => {
          return c.value;
        });
        filterSelectedGradeClasses = filterSelectedGradeClasses.filter(
          (x) => gradeClassesArr.indexOf(x.value) > -1
        );

        this.setState({
          valueGradeClasses: filterSelectedGradeClasses,
        });
        assignGradeClasses(isReset, filteredGradeClasses);
      }
    }

    //set default page = 1 to reset paging number
    fetchData(1, valueFilters);
  }

  multiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex((selected) => selected.label === label);
    const isFirstSelected = index === 0;
    const isLastSelected = index === allSelected.length - 1;
    const count = isFirstSelected ? ` (${allSelected.length}) ` : "";
    const labelSuffix = isLastSelected ? `${label}` : `${label},`;
    const val = `${allSelected.length > 1 ? count : ""}${labelSuffix}`;
    return val;
  };

  formatGroupLabel = (data) => {
    const { classes } = this.props;
    return (
      <div className={classes.groupStyles}>
        <span>{data.label}</span>
        <span className={classes.groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
  };

  customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "initial",
    }),
  };

  render() {
    const { filterOptions, currentPage, classes } = this.props;
    const {
      // valueArrayPageGroup,
      // valueObjectPageGroup,
      valueNamePageGroup,
    } = this.state;

    const defaultOp = { value: "", label: "All" };
    return (
      <Box className={classes.groupFilter}>
        <Grid container spacing={2}>
          {!!filterOptions &&
            filterOptions.map((item, index) => {
              switch (item.type) {
                case "input":
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={classes.filterOption}
                      key={index}
                    >
                      {currentPage === this.groupPage ? (
                        <FormControl>
                          <InputLabel shrink htmlFor={item.name}>
                            {item.label}
                          </InputLabel>
                          <Input
                            id={item.name}
                            size="large"
                            name={item.name}
                            onChange={this.handleChangeInput}
                            value={valueNamePageGroup}
                            // placeholder={item.placeholder}
                          />
                        </FormControl>
                      ) : (
                          <FormControl>
                            <InputLabel shrink htmlFor={item.name}>
                              {item.label}
                            </InputLabel>
                            <Input
                              id={item.name}
                              size="large"
                              name={item.name}
                              onChange={this.handleChangeInput}
                              // placeholder={item.placeholder}
                              className={classes.inputItem}
                            />
                          </FormControl>
                        )}
                    </Grid>
                  );
                case "select":
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      key={index}
                      className={classes.filterOption}
                    >
                      <FormControl>
                        <InputLabel shrink htmlFor={item.name}>
                          {item.label}
                        </InputLabel>
                        {/* <CustomSelect 
                                data={item.options}
                                placeholder={item.name}
                        /> */}
                        <Select
                          name={item.name}
                          defaultValue={item.name === "status" && defaultOp}
                          options={item.options}
                          isDisabled={this.disabledSelect(item.name)}
                          isClearable={false}
                          // isClearable={item.isMulti}
                          value={this.renderValueForSelect(item.name)}
                          isMulti={item.isMulti}
                          closeMenuOnSelect={!item.isMulti}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              neutral0: "white",
                              primary50: "#f0dada",
                            },
                          })}
                          onChange={(selectedOption) =>
                            this.handleChangeSelect(selectedOption, item.name)
                          }
                          // formatGroupLabel={this.formatGroupLabel}
                          hideSelectedOptions={false}
                          styles={this.customStyles}
                          classNamePrefix="select"
                          className={classes.customStyles}
                          isSearchable={false}
                          placeholder={''}
                          components={{
                            MultiValueContainer: this.multiValueContainer,
                          }}
                          formatGroupLabel={formatGroupLabel}
                        />
                      </FormControl>
                    </Grid>
                  );
                default:
                  return null;
              }
            })}
        </Grid>
      </Box>
    );
  }
}

GroupFilter.propTypes = {
  classes: PropTypes.object,
  filterOptions: PropTypes.array,
};

export default withStyles(styles)(GroupFilter);
