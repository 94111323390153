import { fetchApi } from '../utils/apiCaller'
import { GET_STATUS_REQUEST } from '../constants/actions'

export const getRequestUpdate = (params = {}) => {
  return fetchApi('/requestUpdates', 'GET', null, params)
}
export const getRequestUpdateById = (id) => {
  return fetchApi(`/requestUpdates/${id}`, 'GET')
}
export const changeRequestUpdateById = (id, body) => {
  return fetchApi(`/requestUpdates/${id}`, 'PUT', body)
}
export const getRequestUpdateStatus = (params = {}) => {
  return fetchApi(`/setting/applications`, 'GET', null, params)
}
export const getConstantStatus = (status) => {
  return {
    type: GET_STATUS_REQUEST,
    status
  }
}