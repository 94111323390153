import React from 'react';
import  useStyles from './style'

function NotFountPage(props) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div >
        <h1 className={classes.textContent}>
          404 PAGE NOT FOUND
        </h1>     
      </div>
      <div>
        <h5>WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND</h5>
      </div>
    
  </div>
  );
}

export default NotFountPage;