const styles = (theme) => ({
    groupStatus: {
        margin: "30px 0"
    },
    groupItem: {
        display: "flex"
    },
    status: {
        justifyContent: "flex-end",
        fontSize: "16px !important"
    }
  });
  
  export default styles;