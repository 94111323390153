import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import styles from "./styles";
import Header from "./Header";
import Sidebar from "./Sidebar";
// import {
//     Toolbar,
// } from "@material-ui/core";
class Dashboard extends Component {
    render() {
        const { children, classes } = this.props;
        return (
            <div>
                <Header />
                <div className={classes.wrapper}>
                    <Sidebar />
                    <div className={classes.wrapperContent}>
                        <main className={classes.content}>
                            {children}
                        </main>
                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propssTypes = {
    children: PropTypes.object,
    classes: PropTypes.object,
};

export default withStyles(styles)(Dashboard);
