// import { fromJS } from 'immutable';

export const getDefaultState = () => {
  const defaultState = {
    // currentUser: {},
    // users: [],
    paramsTable: {},
  };

  // return immutable
  // return fromJS(defaultState);
  return defaultState
};
