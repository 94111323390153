import React from "react";

const Announcements = React.lazy(() => import("./pages/Announcements"));
const Events = React.lazy(() => import("./pages/Events"));
const AddAnnouncements = React.lazy(() => import("./pages/Announcements/components/add-announcements"));
const Permissions = React.lazy(() => import("./pages/Permissions"));
const ChangeRequest = React.lazy(() => import("./pages/ChangeRequest"));
const ParentChangeRequest = React.lazy(() => import("./pages/ChangeRequest/components/ParentChangeRequest/"));

const routes = [
  {
    path: '/admin',
    name: 'Announcements',
    exact: true,
    component: Announcements
  },
  {
    path: '/admin/announcements',
    name: 'Announcements',
    exact: true,
    component: Announcements
  },
  {
    path: '/admin/events',
    exact: true,
    name: 'Events',
    component: Events
  },
  {
    path: '/admin/announcements/add',
    exact: true,
    name: 'Add Announcements',
    component: AddAnnouncements
  },
  {
    path: '/admin/announcements/edit/:id',
    exact: true,
    name: 'Edit Announcements',
    component: AddAnnouncements
  },
  {
    path: '/admin/announcements/view/:id',
    exact: true,
    name: 'View Announcements',
    component: AddAnnouncements
  },
  {
    path: '/admin/permissions',
    exact: true,
    name: 'View Permissions',
    component: Permissions
  },
  {
    path: '/admin/change-request',
    exact: true,
    name: 'View ChangeRequest',
    component: ChangeRequest
  },
  {
    path: '/admin/change-request/:parentId',
    exact: true,
    name: 'View ParentChangeRequest',
    component: ParentChangeRequest
  },
  // {
  //   path: '',
  //   layout: PublicLayout,
  //   main: NotFoundPage,
  // },
];

export default routes;
