import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import styles from './styles';
import loadingIcon from '../../assets/img/loading.gif'

class GlobalLoading extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.globalLoading}>
                <img src={loadingIcon} alt="loading" className={classes.iconLoading}/>
            </div>
        );
    }
}

GlobalLoading.propTypes = {
    classes: PropTypes.object
}

export default withStyles(styles)(GlobalLoading);
