import Cookies from 'js-cookie';
import { REFRESH_TOKEN, TOKEN_KEY } from '../../constants';

export const createCookie = (name, value, days = 1) => {
    return Cookies.set(name, value, { expires: days });
  };
  
  export const readCookie = name => {
    return Cookies.get(name);
  };

  export const readAllCookie = () => {
    return Cookies.get();
  }
  
  export const eraseCookie = name => {
    return Cookies.remove(name);
  };

  export const clearToken = () => {
    eraseCookie(TOKEN_KEY);
    eraseCookie(REFRESH_TOKEN);
  }

  export const clearAllCookie = () => {
    Object.keys(Cookies.get()).forEach(function(cookieName) {
      var neededAttributes = {
        // Here you pass the same attributes that were used when the cookie was created
        // and are required when removing the cookie
      };
      Cookies.remove(cookieName, neededAttributes);
    });
  };


