const styles = (theme) => ({
  tableCellEmpty: {
    textAlign: "center",
  },
  cellContent: {
    fontSize: "0.95rem !important",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    verticalAlign: "inherit",
    color: "#414042",
  },
  flex: {
    display: "flex",
  },
  descriptionTable: {
    marginTop: "0px !important",
  },
  tableRowDark: {
    backgroundColor: "#f1f1f1",
  },
});

export default styles;
