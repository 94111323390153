const styles = (theme) => ({
  wrapper: {
    backgroundColor: "#f7f7f7",
    display: "flex",
    flexDirection: "row",
    // position: "absolute",
    minHeight: "calc(100vh - 120px)",
    height: "auto",
    overflow: "auto",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  wrapperContent: {
    margin: '15px 20px',
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    border: "1px solid #d6d6d6",
    borderRadius: 3,
    [theme.breakpoints.down("lg")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
      marginLeft: 0,
      width: "100%",
      minHeight: "calc(100% - 170px)",
    },
  },
  content: {
    flexGrow: 1,
    padding: 20,
    [theme.breakpoints.up("xl")]: {
      padding: 50,
    },
  },
  "@global": {
    ".MuiInputBase-root.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      color: "black"
    },
    ".select__multi-value": {
      clear: "both",
      float: "left",
      maxWidth: 300,
      [theme.breakpoints.down("xl")]: {
        maxWidth: "calc(35% + 20px) !important",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "40px !important",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "16vw !important",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "16vw !important",
      },
    },
    ".select__control--is-focused,.select__control--is-focused:hover": {
      border: "none !important",
      boxShadow: "0 0 1px 1px " + theme.palette.primary.main + " !important",
    },
    ".btn-primary" : {
      backgroundColor: theme.palette.primary.main + "!important",
      borderColor: theme.palette.primary.main + "!important"
    }
  },
});

export default styles;
