import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

let openSnackbarFn;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Notifier extends React.Component {
  constructor() {
    super();

    this.state = {
      open: false,
      type: "",
      msg: "",
      redirect: null,
      history: null,
    };
  }

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  openSnackbar = ({ msg, type = "success", redirect = null, history }) => {
    this.setState({
      open: true,
      type: type,
      msg,
      redirect: redirect,
      history: history,
    });

    //set timeout to handle close notify when clickoutside
    setTimeout(() => {
      this.setState({
        open: false,
        msg: ""
      });

      if (history && redirect) {
        //redirect to list page
        history.push(redirect);
      }
    },500)
  };

  handleSnackbarClose = () => {
    const { history, redirect } = this.state;
    this.setState({
      open: false,
      msg: "",
    });

    if (history && redirect) {
      //redirect to list page
      history.push(redirect);
    }
  };

  render() {
    const msg = (
      <span
        id="snackbar-message-id"
        dangerouslySetInnerHTML={{ __html: this.state.msg }}
      />
    );

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // autoHideDuration={2000}
        // onClose={this.handleSnackbarClose}
        open={this.state.open}
        ContentProps={{
          "aria-describedby": "snackbar-message-id",
        }}
      >
        <Alert severity={this.state.type}>{msg}</Alert>
      </Snackbar>
    );
  }
}

export function openSnackbar({ msg, type, redirect, history }) {
  openSnackbarFn({ msg, type, redirect, history });
}

export default Notifier;
