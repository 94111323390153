import { fetchApi } from '../utils/apiCaller';
import { GET_PERMISSION } from '../constants/actions'
/************/
export const login = body => {
  return fetchApi('/auth/login', 'POST', body);
};

export const getPermissionInfo = () => {
  return fetchApi("/permissions/info", "GET");
};

export const handleGetPermission = (userInfo) => {
  return {
    type: GET_PERMISSION,
    userInfo: userInfo
  }
}