const styles = (theme) => ({
  editor: {
    width: "50vw",
    backgroundColor: "#fff",
    [theme.breakpoints.down("xl")]: {
      width: "50vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "65vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "60vw",
    },
  },
  disabledEditor: {
    backgroundColor: "#f5f5f5",
    color: "#a5a5a5",
  },

  "@global": {
    ".note-popover.popover": {
      maxWidth: "none",
      display: "none",
    },
    ".note-editor": {
      border: "1px solid #ddd !important",
    },
    ".tox .tox-editor-header": {
      zIndex: "unset !important",
    },
  },
});

export default styles;
