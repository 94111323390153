import { createMuiTheme } from "@material-ui/core/styles";

const overrideTheme = createMuiTheme({
  palette: {
    common: {
      black: "rgba(207, 10, 44, 1)",
      white: "#fff",
    },
    background: {
      paper: "rgba(254, 254, 254, 1)",
      default: "rgba(255, 255, 255, 1)",
    },
    primary: {
      light: "rgb(255, 252, 253)",
      main: "#00707E",
      dark: "#faa635",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(255, 255, 255, 1)",
      main: "rgba(247, 247, 247, 1)",
      dark: "rgba(190, 190, 190, 1)",
      contrastText: "rgba(207, 10, 44, 1)",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    // text: {
    //     primary: "rgba(207, 10, 44, 1)",
    //     secondary: "rgba(6, 49, 62, 1)",
    //     disabled: "rgba(0, 0, 0, 0.38)",
    //     hint: "rgba(140, 197, 64, 1)",
    // },
  },
  typography: {
    fontFamily: '"Segoe UI",sans-serif',
    fontSize: 15,
    button: {
      textTransform: "none",
      fontFamily: '"Segoe UI",sans-serif',
      fontSize: 16,
    },
    allVariants: {
        fontFamily: '"Segoe UI",sans-serif',
    }
  },
});

const overrideClassses = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        // Adjust spacing to reach minimal touch target hitbox
        minWidth: 100,
        fontWeight: "600",
      },
    },
    MuiInputBase: {
      root: {
        // borderRadius: 4,
        position: "relative",
        border: "1px solid #ced4da",
        fontSize: 16,
        width: "auto",
        padding: "1px 10px",
        // transition: theme.transitions.create(["border-color", "box-shadow"]),
      }
    },
    MuiInput: {
      root: {
        width: "20vw",
        [overrideTheme.breakpoints.down("xl")]: {
          width: "16vw",
        },
        [overrideTheme.breakpoints.down("md")]: {
          width: "14vw",
        },
        [overrideTheme.breakpoints.down("sm")]: {
          width: "38vw",
        },
        [overrideTheme.breakpoints.down("xs")]: {
          width: "70vw",
        },
      },
      inputMultiline: {
        padding : 5
      },
      underline: {
        "&::before" : {
          borderBottom : "none !important"
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontWeight: 600,
        display: "block",
        color: "#414042",
        fontSize: 20,
        marginTop: -10,
      },
    },
    MuiFormControl: {
      root: {
        marginTop: 20,
        width: "100%"
      },
    },
    MuiSelect: {
      root: {
        width: 220,
        [overrideTheme.breakpoints.down("lg")]: {
          width: 150,
        },
        [overrideTheme.breakpoints.down("md")]: {
          width: 120,
        },
        [overrideTheme.breakpoints.down("sm")]: {
          width: "20vw",
        },
      },
      select: {
          width: "100%"
      }
    },
    MuiPagination: {
      root: {
        marginTop: 30,
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        display: "grid",
        justifyContent: "center",
      },
    },
    MuiTableContainer: {
      root: {
        marginTop: 40,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: "#00707E",
        boxShadow: "0 1px 1px 1px rgba(0,0,0,0.3)",
      },
    },
    MuiTableCell: {
      root: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
       },
      head: {
        color: "#fff !important",
        fontWeight: "600",
        textAlign: "center",
        borderRight: "1px solid rgba(255, 255, 255,0.5)",
        overflowWrap: "normal",
        maxWidth: "100px",
        whiteSpace: "break-spaces",
        textOverflow: "clip"
      },
    },
    MuiPopover: {
        paper: {
            marginTop: "3.5rem",
            minWidth: "15rem"
        }
    },
    MuiDialog: {
      paper: {
        overflowY: "visible"
      }
    },
    MuiDialogContent: {
      root: {
        overflowY: "visible",
        margin: '0 auto'
      }
    },
    MuiAppBar: {
      root: {
        width: "100%",
        [overrideTheme.breakpoints.down("xl")]: {
          width: "100%",
        },
        [overrideTheme.breakpoints.down("md")]: {
          width: "100%",
        },
        [overrideTheme.breakpoints.down("sm")]: {
          width: "100%",
        },
        [overrideTheme.breakpoints.down("xs")]: {
          width: "100%",
        },
      }
    },
  },
});

const theme = createMuiTheme({
  palette: overrideTheme.palette,
  typography: overrideTheme.typography,
  overrides: overrideClassses.overrides,
});

export default theme;
