import { title } from "../../../../assets/jss/styles";

const styles = (theme) => ({
  title: {
    ...title,
  },
  panel: {
    padding: "30px 20px",
    borderWidth: "0 1px 1px 1px",
    backgroundColor: "rgba(222,222,222,0.1)",
    borderColor: "rgba(220,220,220,0.5)",
    borderStyle: "solid",
    minHeight: 500,
  },
  editor: {
    minHeight: 250,
    minWidth: "40vw",
    paddingBottom: 200,
  },
  hidden: {
    display: "none !important"
  },
  inputItem: {
    backgroundColor: "#fff",
    width: "100%",
    color: '#414042'
  },
  infoTab: {
    marginTop: 40,
    maxWidth: "100%",
  },
  tabItem: {
    marginTop: 20,
    height: "auto"
  },
  commentInfo: {
    paddingLeft: 15,
    marginTop: 10
  },
  btnItem: {
    margin: "10px 2vw",
  },
  commentItem: {
    color: '#414042 !important',
    width: "100% !important"
  },
  groupSelect: {
    display: "flex",
  },
  groupStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  groupBadgeStyles: {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  },
  error: {
    boxShadow: "0px 0px 1px 1px rgba(245,57,57,0.7) !important",
  },
  errorText: {
    fontSize: "0.8rem",
    color: 'red',
    margin: 5
  },
  errorTextRight: {
    fontSize: "0.8rem",
    float: "right"
  },
  customStyles: {
    marginTop: 15,
    width: "20vw",
    [theme.breakpoints.down("xl")]: {
      width: "17vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "14vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "75vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "65vw",
    },
  },
  "@global": {
    
  },
});

export default styles;
