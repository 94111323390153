import {
  AppBar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import cloneDeep from "clone-deep";
import { isEmpty } from "lodash";
import debounce from "lodash.debounce";
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  actChangeStatus,
  actGetAnnouncementById,
  actGetAnnouncementFilter,
  actPatchPinOnTop,
  actPostAnnouncements,
  actPutAnnouncements,
} from "../../../../actions/announcementActions";
import GlobalLoading from "../../../../commons/GlobalLoading";
import ButtonGroupAnnouncement from "../../../../components/ButtonGroup";
import CustomEditor from "../../../../components/CustomEditor";
import Notifier, { openSnackbar } from "../../../../components/CustomNotify";
import StatusGroupAnnouncement from "../../../../components/StatusGroup";
import { FILTER_ANNOUNCEMENTS } from "../../../../constants/componentConfigs";
import {
  DEBOUNCED_TIME,
  EVENT_STATUS,
  EVENT_TYPE,
  FILTER_NAME,
  LIMIT_SIZE_EDITOR,
  ROLE,
} from "../../../../constants/globalConfigs";
import {
  byteLength,
  cloneObject,
  formatBytes,
} from "../../../../utils/helpers/format";
import styles from "./styles";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);
const multiValueContainer = ({ selectProps, data }) => {
  const label = data.label;
  const allSelected = selectProps.value;
  const index = allSelected.findIndex((selected) => selected.label === label);
  const isFirstSelected = index === 0;
  const isLastSelected = index === allSelected.length - 1;
  const count = isFirstSelected ? ` (${allSelected.length}) ` : "";
  const labelSuffix = isLastSelected ? `${label}` : `${label},`;
  const val = `${allSelected.length > 1 ? count : ""}${labelSuffix}`;
  return val;
};

const filters = FILTER_ANNOUNCEMENTS.slice(0, 4);

const initialState = {
  currentTabValue: 0,
  customFilterAnnoucements: filters,
  customFilterAnnoucementsClone: filters,

  campusVal: [],
  yearGroupsVal: [],
  gradeClassVal: [],

  eventSchool: {},

  changeStatusInfo: {
    comment: null,
    status: EVENT_STATUS.DRAFT,
  },

  announcementInfo: {
    eventTypeId: EVENT_TYPE.ANNOUNCEMENTS,
    pinOnTop: false,
    status: EVENT_STATUS.DRAFT,
    eventSchools: [],
    eventDetails: [
      {
        languageId: "en",
        name: "",
        description: "",
      },
      {
        languageId: "vi",
        name: "",
        description: "",
      },
    ],
  },

  announcementInfoClone: {},

  validation: {
    campusErr: null,
    nameViErr: null,
    desEnErr: null,
    sizeDesEnErr: null,
    desViErr: null,
    sizeDesViErr: null,
    commentErr: null,
  },
  isEdit: false,
  sizeEditorVi: {
    count: 0,
    unit: "Bytes",
  },
  sizeEditorEn: {
    count: 0,
    unit: "Bytes",
  },

  isLoading: false,
  isChangeDesVi: false,
  isChangeDesEn: false
};

class AddAnnouncements extends Component {
  constructor() {
    super();

    this.emitChangeDebounced = debounce(this.emitChangeFilter, DEBOUNCED_TIME);

    this.state = {
      ...initialState,
    };
    this.refVi = React.createRef();
    this.refEn = React.createRef();
  }

  componentDidMount = () => {
    const { match } = this.props;

    //get data for select
    this.getDataFilter();
    const id = match.params.id;
    // inittial input value incase edit staff
    if (id) {
      //get annoucement info by id:
      this.getAnnouncementById(id);
    }
  };

  getAnnouncementById = (id) => {
    const { announcementInfo, changeStatusInfo } = cloneDeep(this.state);
    const cloneAnnouncement = { ...announcementInfo };
    const cloneChangeStatusInfo = { ...changeStatusInfo };
    const defaultSize = {
      count: 0,
      unit: "Bytes",
    };
    //get annoucement info by id:
    actGetAnnouncementById(id).then((response) => {
      if (response && response.data) {
        //assign get value to state
        cloneAnnouncement.pinOnTop = response.data.pinOnTop;
        cloneAnnouncement.status = response.data.status;
        cloneAnnouncement.eventDetails = response.data.eventDetails;
        cloneAnnouncement.eventSchools = response.data.eventSchools;
        cloneAnnouncement.createdBy = response.data.createdBy;
        cloneAnnouncement.createdAt = response.data.createdAt;
        cloneAnnouncement.approvedBy = response.data.approvedBy;
        cloneAnnouncement.approvedAt = response.data.approvedAt;
        cloneAnnouncement.eventTypeId = response.data.eventType.id || 1;
        cloneChangeStatusInfo.status = response.data.status;
        cloneChangeStatusInfo.comment = response.data.comment;

        const sizeVi =
          formatBytes(
            byteLength(cloneAnnouncement.eventDetails[1]?.description)
          ) || defaultSize;

        const sizeEn =
          formatBytes(
            byteLength(cloneAnnouncement.eventDetails[0]?.description)
          ) || defaultSize;

        this.setState({
          changeStatusInfo: cloneChangeStatusInfo,
          announcementInfo: cloneAnnouncement,
          sizeEditorVi: sizeVi,
          sizeEditorEn: sizeEn,
          isEdit: true,
        });

        //map evenschool to select
        if (
          response.data.eventSchools &&
          response.data.eventSchools.length > 0
        ) {
          const eventSchool = response.data.eventSchools;
          this.assignToSelect(eventSchool);
        }
      }
    });
  };

  assignToSelect = (eventSchool) => {
    let campusArr = [],
      yearGroupArr = [],
      gradeClassArr = [],
      tempOptionsCampus = [],
      tempOptionsYearGroups = [],
      tempOptionsGradeClasses = [];

    eventSchool.map((e) => {
      //campus
      let indexCam = campusArr.findIndex((x) => x.id === e.campus.id);
      if (indexCam === -1) {
        campusArr.push(e.campus);
      }

      if (e.yearGroup !== null) {
        let indexYear = yearGroupArr.findIndex((x) => x.id === e.yearGroup.id);

        if (indexYear === -1) {
          yearGroupArr.push(
            Object.assign(e.yearGroup, { campusId: e.campusId })
          );
        }
      }

      if (e.gradeClass !== null) {
        let indexGrade = gradeClassArr.findIndex(
          (x) => x.id === e.gradeClass.id
        );
        if (indexGrade === -1) {
          gradeClassArr.push(
            Object.assign(e.gradeClass, {
              campusId: e.campusId,
              yearGroupId: e.yearGroupId,
            })
          );
        }
      }
    });

    //assign campus
    if (campusArr && campusArr.length > 0) {
      for (let i = 0, iCampuses = campusArr.length; i < iCampuses; i++) {
        tempOptionsCampus.push({
          value: campusArr[i].id,
          label: campusArr[i].name,
        });
      }
    }

    //assign yeargroup
    if (yearGroupArr && yearGroupArr.length > 0) {
      for (let i = 0, iYearGroups = yearGroupArr.length; i < iYearGroups; i++) {
        tempOptionsYearGroups.push({
          value: yearGroupArr[i].id,
          label: yearGroupArr[i].name,
          campusId: yearGroupArr[i].campusId,
        });
      }
    }

    //assign gradeClasses
    if (gradeClassArr && gradeClassArr.length > 0) {
      for (
        let i = 0, iGradeClass = gradeClassArr.length;
        i < iGradeClass;
        i++
      ) {
        tempOptionsGradeClasses.push({
          value: gradeClassArr[i].id,
          label: gradeClassArr[i].name,
          yearGroupId: gradeClassArr[i].yearGroupId,
          campusId: gradeClassArr[i].campusId,
        });
      }
    }

    this.setState({
      campusVal: tempOptionsCampus,
      yearGroupsVal: tempOptionsYearGroups,
      gradeClassVal: tempOptionsGradeClasses,
    });
  };

  getDataFilter = () => {
    actGetAnnouncementFilter().then(
      (response) => {
        const resultCampus = this.getCampusFilter(response[0].data);
        const resultYearGroups = this.getYearGroupFilter(response[1].data);
        const resultGradeClasses = this.getGradeClassesFilter(response[2].data);
        this.assignFilter(resultCampus, resultYearGroups, resultGradeClasses);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  assignFilter = (resultCampus, resultYearGroups, resultGradeClasses) => {
    const filters = this.state.customFilterAnnoucements.slice(0);
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].name === "campus") {
        filters[i].options = resultCampus;
      }

      if (filters[i].name === "yearGroups") {
        filters[i].options = resultYearGroups;
      }

      if (filters[i].name === "gradeClasses") {
        filters[i].options = resultGradeClasses;
      }
    }

    this.setState({
      customFilterAnnoucements: filters,
    });
  };

  getCampusFilter = (data) => {
    let tempOptions = [];
    for (let k = 0, iCampuses = data.length; k < iCampuses; k++) {
      tempOptions.push({
        value: data[k].id,
        label: data[k].name,
      });
    }

    return tempOptions;
  };

  getYearGroupFilter = (data) => {
    let tempOptions = [];
    for (let k = 0, iYearGroups = data.length; k < iYearGroups; k++) {
      tempOptions.push({
        value: data[k].id,
        label: data[k].name,
        campusId: data[k].campusId,
      });
    }

    return tempOptions;
  };

  getGradeClassesFilter = (data) => {
    let tempOptions = [];
    for (let k = 0, iGradeClasses = data.length; k < iGradeClasses; k++) {
      tempOptions.push({
        value: data[k].id,
        label: data[k].name,
        yearGroupId: data[k].yearGroupId,
        campusId: data[k].campusId,
      });
    }

    return tempOptions;
  };

  renderValueForSelect = (name) => {
    switch (name) {
      case "yearGroups":
        return this.state.yearGroupsVal;
      case "campus":
        return this.state.campusVal;
      case "gradeClasses":
        return this.state.gradeClassVal;
      default:
        return [];
    }
  };

  handleChangeInput = (e) => {
    const { announcementInfo } = cloneDeep(this.state);
    const cloneInfo = { ...announcementInfo };
    let val = e.target.value;
    switch (e.target.name) {
      case "nameVi":
        cloneInfo.eventDetails[1].name = val;
        break;
      case "nameEn":
        cloneInfo.eventDetails[0].name = val;
        break;
      case "desVi":
        cloneInfo.eventDetails[1].description = val;
        break;
      case "desEn":
        cloneInfo.eventDetails[0].description = val;
        break;
      default:
        break;
    }

    this.setState({
      announcementInfo: cloneInfo,
    });
    this.resetValidation();
  };

  handleChangeCheckBox = async () => {
    const { match, props } = this.props;
    const id = match.params.id;
    const { announcementInfo } = cloneDeep(this.state);
    const cloneInfo = { ...announcementInfo };
    cloneInfo.pinOnTop = !cloneInfo.pinOnTop;

    this.setState({
      announcementInfo: cloneInfo,
      isLoading: true,
    });

    if (id) {
      try {
        const { status } = await actPatchPinOnTop(id, {
          pinOnTop: cloneInfo.pinOnTop,
        });
        if (status <= 299)
          // this.setState(cloneDeep(initialState));
          openSnackbar({
            msg: "Update Announcement success!",
            type: "success",
            redirect: "/admin",
            history: props.history,
          });
        this.setState({
          isLoading: false,
        });
      } catch (err) {
        console.log(err);
      }
    }

    //remove loading
    this.setState({
      isLoading: false,
    });
  };

  handleChangeComment = (e) => {
    const { changeStatusInfo } = cloneDeep(this.state);
    const cloneInfo = { ...changeStatusInfo };
    let val = e.target.value;
    cloneInfo.comment = val;
    this.resetCommentErr();
    this.setState({
      changeStatusInfo: cloneInfo,
    });
  };

  handleChangeSelect = (options, name) => {
    // const { eventSchool, yearGroupsVal, gradeClassVal } = this.state;
    let tempOptions = [];
    const isReset = options && options.length > 0 ? false : true;

    //reset filters if no options
    if (!(options && options.length > 0)) {
      this.emitChangeDebounced(null, name, true);
    }

    if (name === "campus") {
      for (let i = 0, iCampuses = options.length; i < iCampuses; i++) {
        tempOptions.push({
          value: options[i].value,
          label: options[i].label,
        });
        this.emitChangeDebounced(options[i].value, name, isReset);
      }
      this.setState({
        campusVal: tempOptions,
      });

      this.resetValidation();
    } else if (name === "yearGroups") {
      for (let i = 0, iYearGroups = options.length; i < iYearGroups; i++) {
        tempOptions.push({
          value: options[i].value,
          label: options[i].label,
          campusId: options[i].campusId,
        });
        this.emitChangeDebounced(options[i].value, name, isReset);
      }
      this.setState({
        yearGroupsVal: tempOptions,
      });
    } else if (name === "gradeClasses") {
      for (let j = 0, iGradeClasses = options.length; j < iGradeClasses; j++) {
        tempOptions.push({
          value: options[j].value,
          label: options[j].label,
          yearGroupId: options[j].yearGroupId,
          campusId: options[j].campusId,
        });
      }
      this.setState({
        gradeClassVal: tempOptions,
      });
    }
  };

  handleChangeTab = (event, newValue) => {
    this.setState({
      currentTabValue: newValue,
    });
  };

  handleChangeEditorVi = (content, size) => {
    this.refVi.current = content;
    this.setState({
      sizeEditorVi: size,
      isChangeDesVi: true
    });
    this.resetValidation();
  };

  handleChangeEditorEn = (content, size) => { 
    this.refEn.current = content;
    this.setState({
      sizeEditorEn: size,
      isChangeDesEn: true
    });
    this.resetValidation();
  };
  resetValidation = () => {
    const validInit = {
      campusErr: null,
      nameViErr: null,
      desEnErr: null,
      desViErr: null,
      sizeDesEnErr: null,
      sizeDesViErr: null,
    };
    this.setState({
      validation: validInit,
    });
  };

  resetCommentErr = () => {
    const validData = cloneDeep(this.state.validation);
    validData.commentErr = null;
    this.setState({
      validation: validData,
    });
  };

  handleChangeStatus = async (e, status = EVENT_STATUS.DRAFT) => {
    const { match } = this.props;
    const { changeStatusInfo, isEdit } = this.state;
    const id = match.params.id;

    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    //validation comment when status is Reject

    //get data change status
    const dataChangeStatus = {
      status: status,
    };

    if (
      changeStatusInfo.status === EVENT_STATUS.AWAITING_APPROVAL &&
      status === EVENT_STATUS.DRAFT
    ) {
      if (!this.validationComment()) {
        this.setState({ isLoading: false });
        return;
      }
    }

    this.resetCommentErr();

    if (changeStatusInfo.comment && isEdit) {
      dataChangeStatus.comment = changeStatusInfo.comment;
    }

    //change status
    try {
      const result = await actChangeStatus(id, dataChangeStatus);
      this.setState(cloneDeep(initialState));

      if (result.status === 200) {
        openSnackbar({
          msg: `Change Status Announcement success!`,
          type: "success",
        });
      }
      this.setState({ isLoading: false });

      this.getAnnouncementById(id);
    } catch (error) {
      openSnackbar({
        msg: `Something went wrong!`,
        type: "error",
      });

      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  handleSubmit = async (e, status = "DRAFT") => {
    const { match } = this.props;
    const { announcementInfo, isEdit, isChangeDesVi, isChangeDesEn } = { ...this.state };
    e.preventDefault();

    //get event schools
    this.getData(status);

    if (isEdit && !isChangeDesEn)
      this.refEn.current = announcementInfo.eventDetails[0].description;
    if (isEdit && !isChangeDesVi)
      this.refVi.current = announcementInfo.eventDetails[1].description;

    //validation
    if (!this.validationForm()) return false;
    const id = match.params.id;
    this.setState({
      isLoading: true,
    });

    if (this.refEn?.current)
      announcementInfo.eventDetails[0].description = this.refEn?.current;
    if (this.refVi?.current)
      announcementInfo.eventDetails[1].description = this.refVi?.current;
    try {
      if (id) {
        // put api
        await actPutAnnouncements(id, announcementInfo);
      } else {
        //post api
        await actPostAnnouncements(announcementInfo);
      }

      this.setState(cloneDeep(initialState));
      openSnackbar({
        msg: "Save Announcement success!",
        type: "success",
        redirect: "/admin",
        history: this.props.history,
      });
      this.setState({ isLoading: false });
    } catch (err) {
      openSnackbar({
        msg: `Something went wrong!`,
        type: "error",
      });
      this.setState({ isLoading: false });

      console.log(err);
    }
  };

  validationComment = () => {
    const { changeStatusInfo, validation } = this.state;
    const minLength = 1;

    const cloneValidation = cloneDeep(validation);
    let valid = true;

    const comment = changeStatusInfo.comment;

    //comment
    if (!comment || comment.trim().length < minLength) {
      cloneValidation.commentErr = "Please fill out this field.";
      valid = false;
      this.setState({
        validation: cloneValidation,
      });
    }

    return valid;
  };

  validationForm = () => {
    const {
      announcementInfo,
      validation,
      campusVal,
      sizeEditorEn,
      sizeEditorVi,
    } = this.state;

    let valid = true;
    const minLength = 1,
      maxLength = 255;
    const nameVi = announcementInfo.eventDetails[1].name;
    const nameEn = announcementInfo.eventDetails[0].name;
    const desVi = this.refVi?.current || "";
    const desEn = this.refEn?.current || "";

    if (!campusVal || campusVal.length < 1) {
      validation.campusErr = "Please select at least 1 campus.";
      valid = false;
    }
    //nameEn
    if (nameEn.trim().length < minLength) {
      validation.nameEnErr = "Please fill out this field.";
      valid = false;
      this.setState({
        currentTabValue: 1,
      });
    } else if (nameEn.trim().length > maxLength) {
      validation.nameEnErr = "Can't input more then 255 character.";
      valid = false;
    }

    //desEn
    if (desEn.trim().length < minLength) {
      validation.desEnErr = "Please fill out this field.";
      valid = false;
      this.setState({
        currentTabValue: 1,
      });
    } else if (this.checkSizeEditor(sizeEditorEn)) {
      validation.desEnErr = `You can't input more than ${LIMIT_SIZE_EDITOR.LIMIT} ${LIMIT_SIZE_EDITOR.UNIT}`;
      valid = false;
      this.setState({
        currentTabValue: 1,
      });
    }

    //nameVi
    if (nameVi.trim() === "" || nameVi.trim() === null) {
      validation.nameViErr = "Please fill out this field.";
      valid = false;
      this.setState({
        currentTabValue: 0,
      });
    } else if (nameVi.trim().length > maxLength) {
      validation.nameViErr = "Can't input more then 255 character.";
      valid = false;
    }

    //desVi
    if (desVi.trim().length < minLength) {
      validation.desViErr = "Please fill out this field.";
      valid = false;
      this.setState({
        currentTabValue: 0,
      });
    } else if (this.checkSizeEditor(sizeEditorVi)) {
      validation.desViErr = `You can't input more than ${LIMIT_SIZE_EDITOR.LIMIT} ${LIMIT_SIZE_EDITOR.UNIT}`;
      valid = false;
      this.setState({
        currentTabValue: 0,
      });
    }

    return valid;
  };

  getData = (status) => {
    const { campusVal, yearGroupsVal, gradeClassVal, announcementInfo } = {
      ...this.state,
    };
    //clone annoucementInfo
    let announcementInfoClone = announcementInfo;
    let data = [];

    // get EventSchool
    for (const gradeclass of gradeClassVal) {
      data.push({
        campusId: gradeclass.campusId,
        yearGroupId: gradeclass.yearGroupId,
        gradeClassId: gradeclass.value,
      });
    }
    for (const yearGroup of yearGroupsVal) {
      if (
        data.filter(
          (item) =>
            item.campusId === yearGroup.campusId &&
            item.yearGroupId === yearGroup.value
        ).length === 0
      ) {
        data.push({
          campusId: yearGroup.campusId,
          yearGroupId: yearGroup.value,
          gradeClassId: null,
        });
      }
    }
    for (const campus of campusVal) {
      if (data.filter((item) => item.campusId === campus.value).length === 0) {
        data.push({
          campusId: campus.value,
          yearGroupId: null,
          gradeClassId: null,
        });
      }
    }

    announcementInfoClone.eventSchools = data;
    announcementInfoClone.status = status;
    this.setState({
      announcementInfo: announcementInfoClone,
    });
  };

  checkDisabledSelect = (name) => {
    const { changeStatusInfo, isEdit, campusVal, yearGroupsVal } = this.state;

    const checkCond = (name) => {
      switch (name) {
        case "yearGroups":
          return isEmpty(campusVal);
        case "gradeClasses":
          return isEmpty(yearGroupsVal);
        default:
          return false;
      }
    };
    return (
      (isEdit && changeStatusInfo.status !== EVENT_STATUS.DRAFT) ||
      checkCond(name)
    );
  };

  checkDisabledPinOnTop = () => {
    const { announcementInfo, changeStatusInfo, isEdit } = this.state;
    const extraCond =
      changeStatusInfo.status.indexOf(
        [EVENT_STATUS.APPROVED, EVENT_STATUS.AWAITING_APPROVAL] > -1
      ) && announcementInfo.pinOnTop;

    if (isEdit) {
      if (extraCond) return false;
      else if (changeStatusInfo.status !== EVENT_STATUS.DRAFT) return true;
    }

    return false;
  };

  checkDisabled = () => {
    const { changeStatusInfo, isEdit } = this.state;

    return isEdit && changeStatusInfo.status !== EVENT_STATUS.DRAFT;
  };

  checkSizeEditor = (size) => {
    return (
      size.unit === LIMIT_SIZE_EDITOR.UNIT &&
      size.count > LIMIT_SIZE_EDITOR.LIMIT
    );
  };
  checkDisabledComment = () => {
    const { changeStatusInfo, isEdit } = this.state;
    const { userInfo } = this.props;

    switch (userInfo.role) {
      case ROLE.SYSTEM:
        return [
          EVENT_STATUS.APPROVED,
          EVENT_STATUS.ARCHIVE,
          EVENT_STATUS.DRAFT,
        ].includes(changeStatusInfo.status);
      case ROLE.MANAGER:
        return (
          (userInfo.announcementApprover &&
              [
                  EVENT_STATUS.APPROVED,
                  EVENT_STATUS.ARCHIVE,
                  EVENT_STATUS.DRAFT,
              ].includes(changeStatusInfo.status)) ||
          (!userInfo.announcementApprover &&
              [
                  EVENT_STATUS.APPROVED,
                  EVENT_STATUS.ARCHIVE,
                  EVENT_STATUS.AWAITING_APPROVAL,
                  EVENT_STATUS.DRAFT,
              ].includes(changeStatusInfo.status))
      );
      default:
        return false;
    }
    // return (
    //   isEdit &&
    //   [EVENT_STATUS.AWAITING_APPROVAL].indexOf(changeStatusInfo.status) < 0
    // );
  };

  checkShowComment = () => {
    const { changeStatusInfo, isEdit } = this.state;
    const { userInfo } = this.props;

    return !changeStatusInfo.comment && changeStatusInfo.status === EVENT_STATUS.DRAFT;

    // const condSystem = isEdit && changeStatusInfo.comment;

    // const condShow =
    //   [EVENT_STATUS.APPROVED, EVENT_STATUS.ARCHIVE, EVENT_STATUS.DRAFT].indexOf(
    //     changeStatusInfo.status
    //   ) > -1;

    // switch (userInfo.role) {
    //   case ROLE.SYSTEM:
    //     return (
    //       condSystem ||
    //       changeStatusInfo.status === EVENT_STATUS.AWAITING_APPROVAL
    //     );
    //   case ROLE.MANAGER:
    //     return (
    //       (userInfo.announcementApprover &&
    //         changeStatusInfo.status === EVENT_STATUS.AWAITING_APPROVAL) ||
    //       (condSystem && condShow)
    //     );
    //   default:
    //     return false;
    // }
  };

  emitChangeFilter(value = [], key, isReset = false) {
    const {
      customFilterAnnoucementsClone,
      campusVal,
      yearGroupsVal,
      gradeClassVal,
    } = this.state;

    //Clone data
    let dataFilter = cloneObject(customFilterAnnoucementsClone);
    let filterSelectedYearGroups = cloneObject(yearGroupsVal);
    let filterSelectedGradeClasses = cloneObject(gradeClassVal);

    if (key === FILTER_NAME.CAMPUS) {
      if (isReset) {
        //reset value for all options
        this.setState({
          customFilterAnnoucements: dataFilter,
          yearGroupsVal: [],
          gradeClassVal: [],
        });
      } else {
        //Filter YearGroups By Campus

        const campusArr = campusVal.map((c) => {
          return +c.value;
        });
        const filterYearGroups = dataFilter[2].options.filter(
          (x) => campusArr.indexOf(x.campusId) > -1
        );
        let yearGroupArr = filterYearGroups.map((c) => {
          return c.value;
        });

        filterSelectedYearGroups = filterSelectedYearGroups.filter(
          (x) => yearGroupArr.indexOf(x.value) > -1
        );

        //Filter GradeClasses By YearGroups
        if (filterSelectedYearGroups && filterSelectedYearGroups.length > 0)
          yearGroupArr = filterSelectedYearGroups.map((c) => {
            return +c.value;
          });
        else if (filterYearGroups && filterYearGroups.length > 0)
          yearGroupArr = filterYearGroups.map((c) => {
            return c.value;
          });

        let filterGradeClass = dataFilter[3].options.filter(
          (x) => yearGroupArr.indexOf(x.yearGroupId) > -1
        );

        let gradeClassesArr = filterGradeClass.map((c) => {
          return +c.value;
        });
        filterSelectedGradeClasses = filterSelectedGradeClasses.filter(
          (x) => gradeClassesArr.indexOf(+x.value) > -1
        );
        dataFilter[2].options = filterYearGroups;
        dataFilter[3].options = filterGradeClass;

        this.setState({
          customFilterAnnoucements: dataFilter,
          yearGroupsVal: filterSelectedYearGroups,
          gradeClassVal: filterSelectedGradeClasses,
        });
      }
    } else if (key === FILTER_NAME.YEARGROUPS) {
      let yearGroupArr = [];
      if (isReset) {
        //reset value for grade classes options
        this.setState({
          gradeClassVal: [],
        });
      } else {
        // Filter YearGroups By Campus
        if (campusVal && campusVal.length > 0) {
          const campusArr = campusVal.map((c) => {
            return +c.value;
          });
          const filterYearGroups = dataFilter[2].options.filter(
            (x) => campusArr.indexOf(x.campusId) > -1
          );

          if (yearGroupsVal && yearGroupsVal.length > 0) {
            yearGroupArr = yearGroupsVal.map((c) => {
              return c.value;
            });
          } else {
            yearGroupArr = filterYearGroups.map((c) => {
              return c.value;
            });
          }
          dataFilter[2].options = filterYearGroups;
        } else {
          if (yearGroupsVal && yearGroupsVal.length > 0) {
            yearGroupArr = yearGroupsVal.map((c) => {
              return c.value;
            });
          } else {
            yearGroupArr = dataFilter[2].options.map((c) => {
              return c.value;
            });
          }
        }

        //Filter Grade classes By yeargroups
        let filterGradeClasses = dataFilter[3].options.filter(
          (x) => yearGroupArr.indexOf(x.yearGroupId) > -1
        );
        let gradeClassesArr = filterGradeClasses.map((c) => {
          return c.value;
        });
        filterSelectedGradeClasses = filterSelectedGradeClasses.filter(
          (x) => gradeClassesArr.indexOf(x.value) > -1
        );

        dataFilter[3].options = filterGradeClasses;

        this.setState({
          customFilterAnnoucements: dataFilter,
          gradeClassVal: filterSelectedGradeClasses,
        });
      }
    }
  }
  customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "initial",
      color: "#414042",
    }),
  };

  render() {
    const { classes } = this.props;
    const {
      currentTabValue,
      changeStatusInfo,
      announcementInfo,
      customFilterAnnoucements,
      validation,
      isEdit,
      sizeEditorVi,
      sizeEditorEn,
      isLoading,
    } = cloneDeep(this.state);
    const { userInfo } = this.props;
    const filterOptions = customFilterAnnoucements;
    return (
      <>
        {isLoading && <GlobalLoading />}
        <Grid item sm={12} md={10} lg={10}>
          <Box display="flex">
            <Typography variant="h5" color="primary" className={classes.title}>
              {isEdit ? "Edit Announcement" : "Add Announcement"}
            </Typography>
          </Box>
          {isEdit ? (
            <StatusGroupAnnouncement
              info={announcementInfo}
              status={changeStatusInfo.status}
              isEdit={isEdit}
            />
          ) : null}
          <form onSubmit={this.handleSubmit} style={{ marginTop: 20 }}>
            <FormGroup className={classes.groupSelect}>
              <Grid container spacing={2}>
                {!!filterOptions &&
                  filterOptions.map((item, index) => {
                    if (item.type === "select") {
                      return (
                        <Grid item xs={12} sm={12} md={4} xl={4} key={index}>
                          <FormControl>
                            <InputLabel shrink htmlFor={item.name}>
                              {item.label}
                            </InputLabel>
                            <Select
                              name={item.name}
                              options={item.options}
                              isClearable={false}
                              onChange={(selectedOption) =>
                                this.handleChangeSelect(
                                  selectedOption,
                                  item.name
                                )
                              }
                              value={this.renderValueForSelect(item.name)}
                              isMulti
                              isDisabled={this.checkDisabledSelect(item.name)}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  neutral0: "white",
                                  primary50: "#f0dada",
                                },
                              })}
                              // formatGroupLabel={this.formatGroupLabel}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              styles={this.customStyles}
                              className={`${classes.customStyles} ${
                                item.name === "campus"
                                  ? !validation.campusErr
                                    ? null
                                    : classes.error
                                  : null
                              }`}
                              placeholder={item.label}
                              classNamePrefix={!isEdit ? "select" : null}
                              formatGroupLabel={formatGroupLabel}
                              components={{
                                MultiValueContainer: multiValueContainer,
                              }}
                              isSearchable={false}
                            />
                            {item.name === "campus" ? (
                              <Typography
                                hidden={!validation.campusErr}
                                color="primary"
                                className={classes.errorText}
                              >
                                {validation.campusErr}
                              </Typography>
                            ) : null}
                          </FormControl>
                        </Grid>
                      );
                    } else return null;
                  })}
              </Grid>

              {/* select option */}

              {/* tab */}
              <Box className={classes.infoTab}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={currentTabValue}
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    // textColor="primary"
                    // variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab
                      style={{ outline: "none", color: "#414042" }}
                      value={0}
                      index={0}
                      label="Vietnammese"
                    />
                    <Tab
                      style={{ outline: "none", color: "#414042" }}
                      value={1}
                      index={1}
                      label="English"
                    />
                  </Tabs>
                </AppBar>
                <Box className={classes.panel}>
                  <Box
                    className={classes.TabItem}
                    hidden={currentTabValue !== 0}
                  >
                    <InputLabel shrink htmlFor="nameVi">
                      Name
                    </InputLabel>
                    <Input
                      id="nameVi"
                      disabled={this.checkDisabled()}
                      size="large"
                      name="nameVi"
                      onChange={this.handleChangeInput}
                      className={`${classes.inputItem} ${
                        !validation.nameViErr ? null : classes.error
                      }`}
                      value={announcementInfo.eventDetails[1]?.name || ""}
                      placeholder="Announcement Name"
                    />
                    <Typography
                      hidden={!validation.nameViErr}
                      color="primary"
                      className={classes.errorText}
                    >
                      {validation.nameViErr}
                    </Typography>

                    <div className={classes.tabItem}></div>
                    <InputLabel shrink htmlFor="desVi">
                      Description
                    </InputLabel>
                    <CustomEditor
                      id="desVi"
                      size="large"
                      name="desVi"
                      disabled={this.checkDisabled()}
                      value={announcementInfo?.eventDetails[1]?.description}
                      onChange={this.handleChangeEditorVi}
                    />
                    <Box>
                      <Typography
                        hidden={!validation.desViErr}
                        color="primary"
                        className={classes.errorText}
                      >
                        {validation.desViErr}
                      </Typography>
                      <Typography
                        align="right"
                        display="inline"
                        xs={4}
                        className={classes.errorTextRight}
                        color={
                          this.checkSizeEditor(sizeEditorVi)
                            ? "primary"
                            : "inherit"
                        }
                        variant="body2"
                      >
                        {sizeEditorVi.count + " " + sizeEditorVi.unit}
                      </Typography>
                    </Box>
                  </Box>

                  <Box hidden={currentTabValue !== 1}>
                    <InputLabel shrink htmlFor="nameEn">
                      Name
                    </InputLabel>
                    <Input
                      id="nameEn"
                      size="large"
                      name="nameEn"
                      disabled={this.checkDisabled()}
                      onChange={this.handleChangeInput}
                      className={`${classes.inputItem} ${
                        !validation.nameEnErr ? null : classes.error
                      }`}
                      value={announcementInfo.eventDetails[0]?.name}
                      placeholder="Announcement Name"
                    />
                    <Typography
                      hidden={!validation.nameEnErr}
                      color="primary"
                      className={classes.errorText}
                    >
                      {validation.nameEnErr}
                    </Typography>

                    <div className={classes.tabItem}></div>
                    <InputLabel shrink htmlFor="desEn">
                      Description
                    </InputLabel>
                    <CustomEditor
                      id="desEn"
                      size="large"
                      name="desEn"
                      disabled={this.checkDisabled()}
                      value={
                        announcementInfo.eventDetails[0]?.description || ""
                      }
                      onChange={this.handleChangeEditorEn}
                    />
                    <Box>
                      <Typography
                        hidden={!validation.desEnErr}
                        display="inline"
                        color="primary"
                        xs={8}
                        className={classes.errorText}
                      >
                        {validation.desEnErr}
                      </Typography>
                      <Typography
                        align="right"
                        display="inline"
                        xs={4}
                        className={classes.errorTextRight}
                        color={
                          this.checkSizeEditor(sizeEditorEn)
                            ? "primary"
                            : "inherit"
                        }
                        variant="body2"
                      >
                        {sizeEditorEn.count + " " + sizeEditorEn.unit}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* Pin On Top */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={announcementInfo.pinOnTop}
                    onChange={this.handleChangeCheckBox}
                    disabled={this.checkDisabledPinOnTop()}
                    name="ckPinOnTop"
                    color="primary"
                  />
                }
                label="Pin on the top announcement"
              />

              {/* comment */}
              <Box
                // hidden={!this.checkShowComment()}
                hidden={this.checkShowComment()}
                className={classes.commentInfo}
              >
                <InputLabel shrink htmlFor="nameVi">
                  Comment
                </InputLabel>
                <Input
                  id="comment"
                  disabled={this.checkDisabledComment()}
                  size="large"
                  name="comment"
                  onChange={this.handleChangeComment}
                  className={`${classes.commentItem} ${
                    !validation.commentErr ? null : classes.error
                  }`}
                  // className={classes.commentItem}
                  multiline
                  rows={6}
                  rowsMax={8}
                  value={changeStatusInfo.comment || ""}
                  // placeholder="Comment"
                />
                <Typography
                  hidden={!validation.commentErr}
                  color="primary"
                  className={classes.errorText}
                >
                  {validation.commentErr}
                </Typography>
              </Box>
            </FormGroup>
            <ButtonGroupAnnouncement
              userInfo={userInfo}
              status={changeStatusInfo.status}
              isEdit={isEdit}
              handleSaveDraft={this.handleSubmit}
              handleSubmitDraft={(e) =>
                this.handleSubmit(e, EVENT_STATUS.AWAITING_APPROVAL)
              }
              handleApproval={(e) =>
                this.handleChangeStatus(e, EVENT_STATUS.APPROVED)
              }
              handleArchive={(e) =>
                this.handleChangeStatus(e, EVENT_STATUS.ARCHIVE)
              }
              handleDraft={(e) =>
                this.handleChangeStatus(e, EVENT_STATUS.DRAFT)
              }
            />
          </form>
          <Notifier />
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.permissionReducer.userInfo,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(AddAnnouncements));
