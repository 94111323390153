import { combineReducers } from 'redux'
import paramsTable from '../Reducers/paramsTableReducer'
import permissionReducer from '../Reducers/permissionReducer'
import statusReducer from '../Reducers/statusReducer'

const rootReducer = combineReducers({
  paramsTable,
  permissionReducer,
  statusReducer,
})

export default rootReducer;