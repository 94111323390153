// eslint-disable-next-line no-undef
export const API_URL = process.env.REACT_APP_URL_API;
export const EDITOR_KEY = process.env.REACT_APP_EDITOR_KEY;

// eslint-disable-next-line no-undef
export const LOGIN_URL = process.env.REACT_APP_LOGOUT_URL;
// eslint-disable-next-line no-undef
// export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const TOKEN_KEY = 'jwt';
export const REFRESH_TOKEN = 'refreshToken';
export const USER_INFO = 'user_info';


export const USER_NAME = process.env.REACT_APP_USER_NAME;
export const PASSWORD = process.env.REACT_APP_PASSWORD;