import React, { Component } from "react";
import { Box, Typography, Button, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import GroupFilter from "../../components/GroupFilter";
import PropTypes from "prop-types";
import styles from "./styles";
// import { Link } from "react-router-dom";
import { FILTER_ANNOUNCEMENTS } from "../../constants/componentConfigs";
import CustomTable from "../../components/CustomTable";
import {
  actGetAnnouncementFilter,
  getAnnoucementList,
} from "../../actions/announcementActions";
import {
  formatDate,
  cloneObject,
  // lowerCaseAllWordsExceptFirstLetters
} from "../../utils/helpers/format";
import cloneDeep from "clone-deep";
import { EVENT_STATUS } from "../../constants";
import { isEmpty } from "lodash";

class Announcements extends Component {
  constructor() {
    super();

    const filters = cloneObject(FILTER_ANNOUNCEMENTS);

    this.state = {
      customFilterAnnoucements: filters,
      customFilterAnnoucementsClone: filters,
      dataTable: [],
      paramsTable: {},
      pagination: {
        pageSize: 20,
        current: 1,
        total: 0,
      },
      listCampus: [],
      listYearGroup: [],
      listGradeClass: [],
      listStatus: []
    };
    // this.defaultStudentOptions = [];
  }

  componentDidMount() {
    this.fetchDataTable(this.props.paramsTable);
    this.getDataFilter();
  }

  getDataFilter = () => {
    actGetAnnouncementFilter().then(
      (response) => {
        const resultCampus = this.getCampusFilter(response[0].data);
        const resultYearGroups = this.getYearGroupFilter(response[1].data);
        const resultGradeClasses = this.getGradeClassesFilter(response[2].data);
        const resultStatusList = this.getStatusFilter(response[3].data);
        const resultStatus = resultStatusList.map(item => ({
          label: item.label,
          value: item.label === 'Recall' ? 'ARCHIVE' : item.value,
        }))
        this.assignFilter(resultCampus, resultYearGroups, resultGradeClasses,resultStatus);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  onGetAnnoucementList = (pagination, filters) => {
    this.fetchDataTable({
      ...this.props.paramsTable,
      limit: pagination.pageSizes,
      page: pagination.current,
      ...filters,
    });
  };

  fetchDataTable = async (params = {}) => {
    const customParams = new URLSearchParams();
    customParams.append("limit", params.limit || 20);
    customParams.append("page", params.page || 1);
    customParams.append("languageId", params.languageId || "en");
    customParams.append("sortCol", params.sortCol || "createdAt");
    customParams.append("sortType", params.sortType || "DESC");
    if (params.name) {
      customParams.append("name", params.name);
    }    
    if (params.status && params.status.value !== "") {
      customParams.append("status", params.status.value);
    }
    if (params.campus && params.campus.length > 0) {
      params.campus.forEach((val) => {
        customParams.append("campusId", val.value);
      });
    }
    if (params.yearGroups && params.yearGroups.length > 0) {
      params.yearGroups.forEach((val) => {
        customParams.append("yearGroupId", val.value);
      });
    }
    if (params.gradeClasses && params.gradeClasses.length > 0) {
      params.gradeClasses.forEach((val) => {
        customParams.append("gradeClassId", val.value);
      });
    }

    try {
      const response = await getAnnoucementList(customParams);
      await this.getDataTable(response);
      const paper = { ...this.state.pagination };
      // // Read total count from server
      paper.total = response.data.total;
      paper.current = params.page;

      this.setState({
        pagination: paper,
        paramsTable: params,
      });
    } 
    catch (e) {
      // message.error(getErrorMessage(e));
    }
  };

  getDataTable = (response) => {
    const reponseClone = { ...response };
    let result = [];

    if (reponseClone && reponseClone.data.data.length > 0) {
      reponseClone.data.data.map((val) => {
        let clone = [...val.eventSchools];
        let tableData = {
          name: val.eventDetails[0]?.name,
          createDate: formatDate(val.createdAt, "DD MMM,YYYY"),
          createBy: val.createdBy,
          // approvedDate: formatDate(val.approvedAt, "DD MMM,YYYY"),
          approvedBy: val.approvedBy,
          campus: this.transformEventsSchool(clone, "campus"),
          status: val.status,
          comment: val.comment,
          yearGroup: this.transformEventsSchool(clone, "yearGroup"),
          gradeClass: this.transformEventsSchool(clone, "gradeClass"),
          action:
            val.status === EVENT_STATUS.DRAFT ? (
              <Link style={{color: '#2199e8'}} href={`/admin/announcements/edit/${val.id}`}>Edit</Link>
            ) : (
              <Link style={{color: '#2199e8'}} href={`/admin/announcements/view/${val.id}`}>View</Link>
            ),
        };

        result.push(tableData);
      });
    }

    this.setState({
      dataTable: result,
    });
  };

  transformEventsSchool = (data = [], key) => {
    if (data && data.length > 0) {
      const result = [...new Set(data.map((item) => item[key]?.name))];

      return result;
    }
    return [];
  };

  assignFilter = (resultCampus, resultYearGroups, resultGradeClasses,resultStatus) => {
    const filters = this.state.customFilterAnnoucements.slice(0);
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].name === "campus") {
        filters[i].options = resultCampus;
      }

      if (filters[i].name === "yearGroups") {
        filters[i].options = resultYearGroups;
      }

      if (filters[i].name === "gradeClasses") {
        filters[i].options = resultGradeClasses;
      }

      if (filters[i].name === "status") {
        filters[i].options = resultStatus;
      }
    }

    this.setState({
      customFilterAnnoucements: filters,
      listCampus: resultCampus,
      listYearGroup: resultYearGroups,
      listGradeClasses: resultGradeClasses,
      listStatus: resultStatus
    });
  };

  getCampusFilter = (data) => {
    let tempOptions = [];
    if(!isEmpty(data)) {
      for (let k = 0, iCampuses = data.length; k < iCampuses; k++) {
        tempOptions.push({
          value: data[k].id,
          label: data[k].name,
        });
      }
    }

    return tempOptions;
  };

  getYearGroupFilter = (data) => {
    let tempOptions = [];
    if(!isEmpty(data)) {
      for (let k = 0, iYearGroups = data.length; k < iYearGroups; k++) {
        tempOptions.push({
          value: data[k].id,
          label: data[k].name,
          campusId: data[k].campusId,
        });
      }
    }


    return tempOptions;
  };

  getGradeClassesFilter = (data) => {
    let tempOptions = [];
    if(!isEmpty(data)) {
      for (let k = 0, iGradeClasses = data.length; k < iGradeClasses; k++) {
        tempOptions.push({
          value: data[k].id,
          label: data[k].name,
          yearGroupId: data[k].yearGroupId,
        });
      }
    }

    return tempOptions;
  };

  getStatusFilter = (data) => {
    let tempOptions = [];
    let dataStatus = data && data.length > 0 ? data[0].value : [];

    for (let i = 0, iStatus = dataStatus.length; i < iStatus; i++) {
      tempOptions.push({
        value: dataStatus[i].id,
        label: dataStatus[i].name,
      });
    }

    //insert all at first
    tempOptions.unshift({
      value: '',
      label: 'All'
    })
    return tempOptions;
  };

  assignYearGroups = (isReset, listYearGroupFilter = []) => {
    const {
      customFilterAnnoucements,
      customFilterAnnoucementsClone,
    } = this.state;

    //Clone data
    const dataFilter = cloneDeep(customFilterAnnoucements);
    const dataInit = cloneDeep(customFilterAnnoucementsClone);

    let filterOptions = isReset
      ? dataInit[2].options
      : [...listYearGroupFilter];
    dataFilter[2].options = filterOptions;

    this.setState({ customFilterAnnoucements: dataFilter });
  };

  assignGradeClasses = (isReset, listGradeClassesFilter = []) => {
    const {
      customFilterAnnoucements,
      customFilterAnnoucementsClone,
    } = this.state;

    //Clone data
    const dataFilter = cloneDeep(customFilterAnnoucements);
    const dataInit = cloneDeep(customFilterAnnoucementsClone);

    let filterOptions = isReset
      ? dataInit[3].options
      : [...listGradeClassesFilter];
    dataFilter[3].options = filterOptions;

    this.setState({ customFilterAnnoucements: dataFilter });
  };

  render() {
    const { classes } = this.props;
    const {
      customFilterAnnoucements,
      customFilterAnnoucementsClone,
      paramsTable,
      dataTable,
      pagination,
    } = { ...this.state };
    // const { AnnouncementData, onGetAnnoucementList } = this.props;
    return (
      <div>
        <Box display="flex">
          <Typography variant="h5" color="primary" className={classes.title}>
            Announcements
          </Typography>
          <Link href="/admin/announcements/add">
            <Button variant="contained" size="medium" className={classes.primaryButton}>
              Add
            </Button>
          </Link>
        </Box>
        <Box>
          <Box className={classes.filter}>
            <GroupFilter
              filterOptions={customFilterAnnoucements}
              filterOptionsInit={customFilterAnnoucementsClone}
              urlParams={dataTable}
              fetchData={this.onGetAnnoucementList}
              assignYearGroups={this.assignYearGroups}
              assignGradeClasses={this.assignGradeClasses}
            />
          </Box>
          <Box>
            <CustomTable
              tableName={"announcements"}
              tableHead={[
                "Name",
                "Created Date",
                "Created By",
                "Status",
                // "Approved Date",
                "Approved By",
                "Campus",
                "Year Group",
                "Grade Class",
                "Actions",
              ]}
              tableData={dataTable}
              fetchData={this.onGetAnnoucementList}
              filterParams={{ ...paramsTable }}
              pagination={pagination}
            />
          </Box>
        </Box>
      </div>
    );
  }
}

Announcements.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Announcements);
