import { GET_PERMISSION } from '../constants/actions'
const initialState = {
  userInfo: {}
}

const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERMISSION: {
      state.userInfo = action.userInfo;
      return { ...state }
    }
    default:
      return state
  }
}

export default permissionReducer