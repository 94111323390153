const styles = (theme) => ({
  groupFilter: {
    display: "flex",
  },
  groupStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  groupBadgeStyles: {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  },
  filterOption: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
      maxWidth: "20%",

    },
  },
  inputItem: {
    width: "100% !important",
    [theme.breakpoints.down("xl")]: {
      width: "100% !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "38vw !important",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70vw  !important",
    },
    height: 38,
    borderRadius: 2
  },
  customStyles: {
    // minWidth: "16vw",
    marginTop: 15,
    width: "18vw",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "38vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70vw",
    },
  }
});

export default styles;
