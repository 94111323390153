import { EVENT_STATUS } from "./globalConfigs";

export const FILTER_ANNOUNCEMENTS = [
  {
    type: "input",
    label: "Name",
    name: "name",
    placeholder: "Name",
  },
  {
    type: 'select',
    label: 'Campus',
    name: 'campus',
    isMulti: true,
    placeholder: 'Select Campus',
    options: []
  },
  {
    type: 'select',
    label: 'Year Groups',
    name: 'yearGroups',
    isMulti: true,
    placeholder: 'Select Year Group',
    options: []
  },
  {
    type: 'select',
    label: 'Grade Classes',
    name: 'gradeClasses',
    isMulti: true,
    placeholder: 'Select Grade Class',
    options: []
  },
  {
    type: 'select',
    label: 'Status',
    name: 'status',
    isMulti: false,
    placeholder: 'Select Status',
    options: []
  },
];
export const FILTER_PERMISSIONS = [
  {
    type: "input",
    label: "Staff User",
    name: "staffUser",
    placeholder: "Name",
  },
  {
    type: 'select',
    label: 'Campus',
    name: 'campus',
    placeholder: 'Select Campus',
    isMulti: true,
    options: []
  },
  {
    type: 'select',
    label: 'Year Groups',
    name: 'yearGroups',
    placeholder: 'Select Year Group',
    isMulti: true,
    options: []
  },
  {
    type: 'select',
    label: 'Grade Classes',
    name: 'gradeClasses',
    placeholder: 'Select Grade Class',
    isMulti: true,
    options: []
  },
  {
    type: 'checkbox',
    label: "Announcement Approver",
    name: 'announcementApprover',
  },
  {
    type: 'checkbox',
    label: "After School Activity Admin",
    name: 'afterSchoolActivityAdmin',
  },
  {
    type: 'checkbox',
    label: "Chat History Management Admin",
    name: 'chatHistoryAdmin',
  },
];
export const FILTER_CHANGE_REQUEST = [
  {
    type: 'select',
    label: 'Campus',
    name: 'campus',
    placeholder: 'Select Campus',
    isMulti: true,
    options: []
  },
  {
    type: 'select',
    label: 'Year Groups',
    name: 'yearGroups',
    placeholder: 'Select Year Group',
    isMulti: true,
    options: []
  },
  {
    type: 'select',
    label: 'Grade Classes',
    name: 'gradeClasses',
    placeholder: 'Select Grade Class',
    isMulti: true,
    options: []
  },
  {
    type: 'select',
    label: 'Status',
    name: 'status',
    placeholder: '',
    // isMulti: true,
    options: [],
    typeData: 'remote_data',
  },
];
export const FILTER_NAME = {
  CAMPUS: 'campus',
  YEARGROUPS: 'yearGroups',
  GRADECLASSES: 'gradeClasses',
};