const title = { 
    flexGrow: 1,
    fontWeight : 550,
    color: "#00707E"
};

const filterSection = { 
    marginTop: 30
}

export {
    title,
    filterSection
};