const styles = (theme) => ({
  groupButton: {
      padding: 20,
  },
  hidden: {
      display: "none !important",
  },
  btnItem: {
      height: "35px !important",
      margin: "10px 1.5vw",
      backgroundColor: "#00707E",
      color: "#fff",
      borderRadius: "4px",
      "&:hover": {
          backgroundColor: "#faa635",
          color: "#fff",
      },
  },
  btnHover: {
      "&:hover": {
          backgroundColor: "#faa635 !important",
      },
  },
});

export default styles;