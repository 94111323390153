import { makeStyles } from '@material-ui/core/styles';
 const useStyles = makeStyles((theme) => ({
 wrapper:{
   backgroundColor: theme.palette.background.default,
   width:"100%",
   height:"100vh",
   display:"flex",
   justifyContent:'center',
   alignItems:"center",
   flexDirection:'column'
  },
  textContent:{
    color:theme.palette.primary.main,
    fontWeight:'600',
    fontSize:50,
    textShadow:'1px 2px 3px #cf0a2c'
  }
 
}));
export default useStyles