import React, { Component, Suspense } from "react";
import { Route } from "react-router-dom";
import Dashboard from "../../../components/Dashboard";
import PropTypes from "prop-types";
import Spin from "../../../components/Spin";

class AdminLayoutRoute extends Component {
  render() {
    const { component: ComponentName, ...remainProps } = this.props;
      return (
        <Route
          {...remainProps}
          render={(routeProps) => {
            return (
              <Dashboard>
                <Suspense fallback={<Spin isLoading={true} />}>
                  <ComponentName {...routeProps} />
                </Suspense>
              </Dashboard>
            );
          }}
        />
      );
    }
}

AdminLayoutRoute.propTypes = {
  path: PropTypes.string,
  exact: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  name: PropTypes.string,
};

export default AdminLayoutRoute;
