import React, { Component } from "react";
import { Switch, Router } from "react-router-dom";
import "./App.scss";
import routes from './routes'
import CssBaseline from '@material-ui/core/CssBaseline';
import AdminLayoutRoute from "./commons/Layout/AdminLayoutRoute";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./commons/Theme";
import { history } from './utils/history';
import { getPermissionInfo, handleGetPermission } from "./actions/";
import { withStyles } from "@material-ui/styles";
import { styles } from "./AppStyles";

import NotFoundPage from './pages/NotFoundPage'
import { Route } from "react-router-dom"
import { connect } from 'react-redux'

// import GlobalLoading from "./commons/GlobalLoading";

class App extends Component {

  async componentDidMount() {
    try {
      const { data } = await getPermissionInfo()
      this.props.handleGetPermission(data)
    }
    catch (e) {
      throw e
    }
  }

  renderAdminRoutes = (routes) => {
    let appRoute = null;
    if (routes.length > 0) {
      appRoute = routes.map((route, index) => {
        return (
          <AdminLayoutRoute
            key={index}
            path={route.path}
            component={route.component}
            exact={route.exact}
            name={route.name}
          />
        );
      });
    }
    return appRoute;
  };


  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Router history={history}>
            <Switch>
              {this.renderAdminRoutes(routes)}
              <Route path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </Router>
        </CssBaseline>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetPermission: (permission) => {
      dispatch(handleGetPermission(permission))
    }
  }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(App));