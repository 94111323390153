import { fetchApi, fetchAllApi } from "../utils/apiCaller";

export const getAnnoucementList = (params = {}) => {
  return fetchApi("/events", "GET", null, params);
};

export const uploadImg = (body = {}) => {
  return fetchApi("/files", 'POST', body, null, null);
};

export const actPostAnnouncements = (body = {}) => {
  return fetchApi(`/events`, "POST", body, null, null);
};

export const actPutAnnouncements = (id,body = {}) => {
  return fetchApi(`/events/${id}`, "PUT", body, null, null);
};

export const actPatchPinOnTop = (id,body = {}) => {
  return fetchApi(`/events/updatePin/${id}`, "PATCH", body, null, null);
};


export const actChangeStatus = (id,body = {}) => {
  return fetchApi(`/events/changeStatus/${id}`, "PUT", body, null, null);
};

export const getAnnouncementStatus = (params = {}) => {
  return fetchApi(`/setting/applications`, 'GET', null, params)
}

export const actGetAnnouncementFilter = () => {
  // on loading api
  return fetchAllApi([
    actGetCampus(),
    actGetYearGroups(),
    actGetGradeClasses(),
    getAnnouncementStatus({key: 'EVENT_STATUS'})
  ]);
};

export const actGetAnnouncementById = (id,cancelToken) => {
  return fetchApi(`/events/${id}`, "GET", null, null, cancelToken);
};

export const actGetCampus = (cancelToken) => {
  return fetchApi(`/campuses/getCampuses`, "GET", null, null, cancelToken);
};

export const actGetYearGroups = (cancelToken) => {
  return fetchApi(`/yearGroups`, "GET", null, null, cancelToken);
};

export const actGetGradeClasses = (cancelToken) => {
  return fetchApi(`/gradeClasses`, "GET", null, null, cancelToken);
};
