import { title, filterSection } from "../../assets/jss/styles";

const styles = () => ({
    title: {
        ...title,
    },
    filter: {
        ...filterSection,
    },
    primaryButton: {
        backgroundColor: "#00707E",
        color: "#fff",
        borderRadius: "4px",
        border: "1px solid #00707E",

        "&:hover": {
            border: "1px solid #faa635",
            backgroundColor: "#faa635",
            color: "#fff",
        },
    },
});

export default styles;
