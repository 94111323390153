export const DEBOUNCED_TIME = 500;
export const DEBOUNCED_TIME_EDITOR = 200;

export const EVENT_TYPE = {
  ANNOUNCEMENTS: 1,
  EVENTS: 2,
};

export const ROLE = {
  SYSTEM: "system",
  MANAGER: "manager"
};

export const EVENT_STATUS = {
  DRAFT: "DRAFT",
  AWAITING_APPROVAL: "AWAITING_APPROVAL",
  APPROVED: "APPROVED",
  // REJECTED : "REJECTED",
  ARCHIVE: "ARCHIVE",
};

export const FILTER_NAME = {
  NAME: "name",
  CAMPUS: "campus",
  YEARGROUPS: "yearGroups",
  GRADECLASSES: "gradeClasses",
  // NO_ACCESS_CARD: 'noAccessCard'
};

export const LIMIT_SIZE_EDITOR = {
  UNIT: "KB",
  LIMIT: 500
};