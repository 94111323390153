import theme from "../Theme";

const styles = () => ({
    globalLoading:{
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 999,
        background: 'rgba(0,0,0,0.4)'
    },
    iconLoading: {
        left: 0,
        right: 0,
        position: "fixed",
        marginLeft: "auto",
        marginRight: "auto",
        top: "50%",
        width: 30,
    }
});

export default styles;